<div class="title">Network Events</div>

<!--<app-noitems [typeName]="'timeline events'" [isEmpty]="eventData.length==0 && !isLoading" ></app-noitems>-->
<div *ngIf="eventData.length === 0 && !isLoading && canReadElasticSearch" class="noData" id="NoArea">No Data</div>
<div *ngIf="eventData.length === 0 && !isLoading && !canReadElasticSearch" class="noData" id="NoArea">
    No Permissions
</div>

<app-loader [isLoading]="isLoading"></app-loader>

<div
    *ngIf="eventData.length > 0 && !isLoading"
    class="row"
    id="NetworkTimeline"
    style="height: calc(100% - 50px); padding-left: 0px; padding-right: 41px"
>
    <div *ngFor="let alarm of eventData; let i = index; trackBy: trackByTimestamp">
        <div [ngClass]="{ hidden: !isNewDate(i) }" class="timeline">
            <span class="date">{{ formatDate(alarm['@timestamp']) }}</span>
        </div>
        <div class="timeline item">
            <div class="clock icon-Icon_Alarm"></div>
            <div class="connector"></div>
            {{ formatTime(alarm['@timestamp']) }}&nbsp;

            <!-- legacy vtc offline event -->
            <span
                *ngIf="alarm['event_type'] === 'router-offline'"
                class="bubble severityError"
                style="margin-right: 0px"
            ></span>
            <span *ngIf="alarm['event_type'] === 'deleted'" class="bubble v7Offline" style="margin-right: 0px"></span>
            <span
                *ngIf="alarm['event_type'] === 'router-online' || alarm['event_type'] === 'created'"
                class="bubble severityInfo"
                style="margin-right: 0px"
            ></span>

            <span *ngIf="alarm[eventFields.edgeRouterName] !== undefined" class="name">
                {{ alarm[eventFields.edgeRouterName] }}
            </span>
            <span *ngIf="alarm[eventFields.identityName] !== undefined" class="name">
                {{ alarm[eventFields.identityName] }}
            </span>

            <br />
            <div
                *ngIf="
                    alarm['event_type'] !== undefined &&
                    alarm['event_type'] !== 'created' &&
                    alarm['event_type'] !== 'deleted'
                "
                class="details"
            >
                <span *ngIf="alarm[eventFields.identityId]">Endpoint:</span>
                <span *ngIf="alarm[eventFields.edgeRouterId]">Edge Router:</span>
                {{ alarm.event_type }}
            </div>
            <div *ngIf="alarm['event_type'] === 'created'" class="details">
                <span *ngIf="alarm[eventFields.identityId]">Endpoint:</span>
                <span *ngIf="alarm[eventFields.edgeRouterId]">Edge Router:</span>
                Online
            </div>
            <div *ngIf="alarm['event_type'] === 'deleted'" class="details">
                <span *ngIf="alarm[eventFields.identityId]">Endpoint:</span>
                <span *ngIf="alarm[eventFields.edgeRouterId]">Edge Router:</span>
                Offline
            </div>
        </div>
    </div>
</div>
