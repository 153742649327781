import { MopResource } from './mopresource';

export class NetworkGroup extends MopResource {
    public organizationShortName: string;
    public status: number;
    public defaultAzureVirtualWanId: string;
    public mfaRequired: boolean;
    public zitiEnabled: boolean;
    public awsAutoScalingGatewayEnabled: boolean;
    public active: boolean;
    public billingAccountId: string;

    public getSaveObject() {
        return {
            name: this.name,
            defaultAzureVirtualWanId: this.defaultAzureVirtualWanId,
            mfaRequired: this.mfaRequired,
            zitiEnabled: this.zitiEnabled,
            awsAutoScalingGatewayEnabled: this.awsAutoScalingGatewayEnabled,
            active: this.active,
        };
    }
}
