import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Auth0Service, IamService, LOGIN_SERVICE, LoginService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';
import moment from 'moment';

@Component({
    selector: 'app-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss'],
})
export class InvitationComponent implements OnInit {
    environmentLogoClass = this.environment['logoClass'] != null ? this.environment['logoClass'] : '';

    screen;
    key;
    tenantLabel;
    expires;

    provider;

    dialogRef;

    // invitation model
    invitation = {
        fromIdentity: {
            firstName: '',
            lastName: '',
            email: '',
        },
        invitedEmailAddress: '',
        toTenantName: '',
        expiration: {
            nano: 0,
            epochSecond: 0,
        },
        accepted: null,
    };

    acceptResult;
    identityProviders = [];

    constructor(
        private logger: LoggerService,
        private iamService: IamService,
        private activatedRoute: ActivatedRoute,
        private auth0Service: Auth0Service,
        @Inject(LOGIN_SERVICE) private auth: LoginService,
        public dialogForm: MatDialog,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        // clear out any stray logins
        this.auth.clearStorage();
    }

    ngOnInit() {
        // getting the path params from the current route
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            // If we have an invite key, show the splash page
            if (params['invitationKey'] && params['nfToken'] && params['nfEmail'] && params['tenantLabel']) {
                this.completeSelfService(
                    params['invitationKey'],
                    params['nfToken'],
                    params['nfEmail'],
                    params['tenantLabel']
                );
            } else if (params['invitationKey']) {
                this.key = params['invitationKey'];
                this.reviewInvitation();
            } else if (params['acceptToken'] && params['state']) {
                this.confirmInvitation(params['acceptToken'], params['state']);
            } else {
                this.screen = 'empty';
            }
        });
    }

    async accept() {
        this.logger.info('Initiate accepting invite...');

        await this.getIdentityProviders();

        this.iamService.acceptInvite(this.key).subscribe((res: any) => {
            this.logger.info('ACCEPT invite response, popping lock', res);
            const nfToken = res?.nfToken;
            if (nfToken) {
                if (this.identityProviders) {
                    this.createNewUserAccount(nfToken, this.invitation['invitedEmailAddress'], this.tenantLabel);
                } else {
                    alert(
                        'No identity providers returned for this organization. Please contact support of this issues persists.'
                    );
                    this.logger.error(JSON.stringify(res));
                }
            } else {
                alert('Missing a valid nfToken from this invite key. See the console for more details');
                this.logger.error(JSON.stringify(res));
            }
        });
    }

    async completeSelfService(invitationKey, nfToken, nfEmail, tenantLabel) {
        this.logger.info('Complete self service signup...');
        this.tenantLabel = tenantLabel;
        await this.getIdentityProviders(true);

        if (nfToken) {
            this.key = invitationKey;
            localStorage.setItem('inviteKey', this.key);
            localStorage.setItem(`nf_new_self_service_${tenantLabel}`, 'true');
            localStorage.setItem(`showGettingStarted_${tenantLabel}`, 'true');
            localStorage.setItem(`showSurvey_${tenantLabel}`, 'true');
            localStorage.setItem(`createNetwork_${tenantLabel}`, 'true');
            this.acceptResult = await this.iamService.acceptInvite(invitationKey).toPromise();
            this.createNewUserAccount(nfToken, nfEmail, tenantLabel, true);
        } else {
            alert('Missing a valid nfToken from this invite key. See the console for more details');
            this.logger.error(`signup token=${nfToken}, signup email=${nfEmail}`);
        }
    }

    async createNewUserAccount(nfToken, nfEmail, tenantLabel, isSelfService?: boolean) {
        const idps = [];
        this.identityProviders?.forEach((idp) => {
            idps.push({ connectionId: idp.auth0ConnectionId, name: idp.name });
        });
        const state = {
            appState: {
                nfToken: nfToken,
                target: '/main',
                identityProviders: this.identityProviders,
            },
            nfToken: nfToken,
            nfEmail: nfEmail,
            tenantLabel: tenantLabel,
            allowSignup: true,
            showSignup: true,
            allowSignin: !isSelfService,
            isInvitation: !isSelfService,
            identityProviders: JSON.stringify(idps),
            redirect_uri: this.environment.authconfig.callbackURL,
        };
        await this.auth0Service.loginWithRedirect(state);
        localStorage.setItem('newInvitedUser_' + tenantLabel, 'true');
    }

    async getIdentityProviders(preventIDPSelector = false) {
        this.identityProviders = [];
        await this.iamService
            .getOrgByShortName(this.tenantLabel)
            .toPromise()
            .then((organization) => {
                this.identityProviders = organization['identityProviders'];
            });
    }

    decline() {
        this.iamService.declineInvite(this.key).subscribe(() => {
            this.screen = 'declined';
        });
    }

    reviewInvitation() {
        this.logger.info('Reviewing the invite. Invitation this.key is: ' + this.key);

        this.iamService.getBy('invitations', 'key', this.key).subscribe(
            (res: any) => {
                this.logger.info('Invitation result', res);
                this.invitation = res;

                // @TODO Need a screen for an invitation already being used
                if (this.invitation) {
                    this.tenantLabel = this.invitation['toTenantLabel'];
                    const expireMoment = moment.unix(this.invitation.expiration.epochSecond);
                    this.expires = expireMoment.fromNow();

                    if (this.invitation['accepted']) {
                        this.screen = 'inuse';
                    } else if (this.invitation['state'] === 'Expired') {
                        this.screen = 'expired';
                    } else if (this.invitation['state'] === 'Revoked') {
                        this.screen = 'revoked';
                    } else if (this.invitation['accepted'] == null) {
                        this.screen = 'review';
                    } else {
                        this.screen = 'declined';
                    }
                } else {
                    this.screen = 'invalid';
                }
                localStorage.setItem('inviteKey', this.key);
            },
            () => {
                this.screen = 'invalid';
            }
        );
    }

    confirmInvitation(acceptToken, state) {
        this.logger.info('Confirming the invite...');
        const key = localStorage.getItem('inviteKey');

        this.logger.info('Invite Key Is: ' + key);
        this.logger.info('Accept Token Is: ' + acceptToken);

        this.iamService.confirmInvite(key, acceptToken).subscribe((res) => {
            this.logger.info('Confirm invite', res);

            // invite should be confirmed
            if (state) {
                this.key = localStorage.removeItem('inviteKey');
                this.auth.continue(state);
            } else {
                alert('Unable to resume authorization, please try a fresh login or have a new invitation sent');
            }
        });
    }
}
