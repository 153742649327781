import { Component, OnDestroy, OnInit } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ApiService,
    BrandingModeService,
    FeatureService,
    IdentityPreferencesService,
    LoggerService,
} from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Subscription } from 'rxjs';
import {cloneDeep} from 'lodash';
import {NetworkServiceV2} from "@netfoundry-ui/shared/apiv2";

const DEFAULT_NETWORK_ID_KEY = 'default_network_id';
const DEFAULT_APP_ID_KEY = 'default_app_id';
const DEFAULT_NETWORK_ITEM = {
  name: 'Select a Network'
};

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
    company = '';
    domain = '';
    subscription = new Subscription();
    tenantLabel = '';
    userPreferences: any = {};
    orgPreferences: any = {};
    isLoading = false;
    defaultNetwork: any = DEFAULT_NETWORK_ITEM;
    defaultNetworkId = 'default';
    networks = [];
    appOptions = [
      {
        id: 'networks',
        name: 'Network',
        hidden: !this.authorizationService.canListNetworks()
      },
      {
        id: 'organization',
        name: 'Organization',
        hidden: !this.authorizationService.canListOrganizations()
      },
      {
        id: 'billing',
        name: 'Billing',
        hidden: !this.authorizationService.canViewBillingSubscriptions() && !this.authorizationService.canViewEnterpriseBillingSubscriptions()
      },
      {
        id: 'browzer',
        name: 'BrowZer',
        hidden: !this.authorizationService.canListNetworks()
      }
    ];

    defaultAppId: any = this.appOptions[0].id;

    constructor(
        public brandingService: BrandingModeService,
        public growlerService: GrowlerService,
        public featureService: FeatureService,
        public identityPreferences: IdentityPreferencesService,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        private logger: LoggerService,
        public networkService: NetworkServiceV2
    ) {}

    ngOnInit() {
        this.isLoading = true;
        const html = document.getElementsByTagName('html')[0];

        this.brandingService.fetchBrandCss().then((rawCss) => {
            html.style.cssText = rawCss;
        });
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                this.tenantLabel = tenant.label;
            })
        );
        this.apiService.currentUserPreferences.subscribe((userPreferences) => {
            this.userPreferences = cloneDeep(userPreferences?.preferences);
            if (!this.userPreferences) {
                this.userPreferences = {};
            }
            this.isLoading = false;
        });
        this.subscription.add(
            this.networkService._networks.subscribe((networks) => {
                this.networks = cloneDeep(networks);
                this.addResetOption();
                this.findDefaultNetwork();
            })
        );
        this.initPreferences();
        this.initNetworks();
    }

    initNetworks() {
      this.networkService.getNetworks({ pageParams: { size: 2000, page: 0 } }).then((networks: any) => {
        this.networks = cloneDeep(networks);
        this.addResetOption();
        this.findDefaultNetwork();
      });
    }

    addResetOption() {
      this.networks.push({
        id: 'clear-default-network',
        status: 'OFFLINE',
        iconClass: 'reset-icon icon-BigSoftMinus',
        label: 'No Default Network'
      });
    }

    initPreferences() {
      this.defaultAppId = this.identityPreferences.getPreference(DEFAULT_APP_ID_KEY);
    }

    findDefaultNetwork() {
      this.defaultNetworkId = this.identityPreferences.getPreference('default_network_id');
      this.networks?.forEach((network) => {
        if (this.defaultNetworkId === network.id) {
          this.defaultNetwork = network;
        }
      });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    toggleDarkMode() {
        this.brandingService.darkModeEnabled = !this.brandingService.darkModeEnabled;
        localStorage.setItem('darkMode_' + this.tenantLabel, this.brandingService.darkModeEnabled.toString());
    }

    togglePowerUserSetting() {
        if (!this.userPreferences) {
            this.userPreferences = {};
        }
        this.userPreferences.powerUserEnabled = !this.userPreferences?.powerUserEnabled;
        this.featureService.setPowerUserEnabled(this.userPreferences.powerUserEnabled);
    }

    toggleSideNavigationSetting() {
        if (!this.userPreferences) {
            this.userPreferences = {};
        }
        this.userPreferences.enableNewNavigation = !this.userPreferences?.enableNewNavigation;
        this.featureService.enableNewNavigation = this.userPreferences.enableNewNavigation;
    }

    copyToClipboard() {
        const setlocalStorage = Object.entries(localStorage);
        const bearerJwtObjectArray = setlocalStorage.find(([key]) => /@@auth0spa*/.test(key));
        const bearerJwtObject = JSON.parse(bearerJwtObjectArray[1]);
        const bearerJwt = bearerJwtObject.body.access_token;
        navigator.clipboard.writeText(bearerJwt);
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Copied to clipboard', 'Jwt token has been copied to your clipboard')
        );
    }

    setDefaultNetwork(event) {
      if (this.defaultNetwork?.id === 'clear-default-network') {
        this.identityPreferences.setPreference(DEFAULT_NETWORK_ID_KEY, undefined);
        this.defaultNetwork = DEFAULT_NETWORK_ITEM;
        this.defaultNetworkId = 'default';
      } else {
        this.identityPreferences.setPreference(DEFAULT_NETWORK_ID_KEY, this.defaultNetwork?.id);
      }
    }

    setDefaultApp(event) {
      this.identityPreferences.setPreference(DEFAULT_APP_ID_KEY, this.defaultAppId);
    }
}
