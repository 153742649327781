import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZitiDashboardComponent } from '@netfoundry-ui/page/ziti-dashboard';
import { EdgeRouterPoliciesComponent } from '@netfoundry-ui/feature/ziti/edge-router-policies';
import { AzureVirtualWansComponent } from '@netfoundry-ui/page/azurevirtualwans';
import { UserSettingsComponent } from '@netfoundry-ui/page/user-settings';
import { CallbackComponent } from '@netfoundry-ui/page/callback';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CertificateAuthoritiesComponent } from '@netfoundry-ui/page/certificate-authorities';
import { DashboardComponent } from '@netfoundry-ui/page/dashboard';
import { InvitationComponent } from '@netfoundry-ui/page/invitation';
import { LoginComponent } from '@netfoundry-ui/page/login';
import { LogsComponent } from '@netfoundry-ui/page/logs';
import { MetricsComponent } from '@netfoundry-ui/page/metrics';
import { NetflowCollectorsComponent } from '@netfoundry-ui/page/netflow-collectors';
import { NetworkGroupsComponent } from '@netfoundry-ui/page/network-groups';
import { NetworksCombinedComponent } from '@netfoundry-ui/page/networks-combined';
import { NetworksComponent } from '@netfoundry-ui/page/networks';
import { RolesComponent } from '@netfoundry-ui/page/roles';
import { RedirectGuard, SelfserviceComponent } from '@netfoundry-ui/page/selfservice';
import { TestpickboxComponent } from '@netfoundry-ui/page/test-pick-box';
import { UserinfoComponent } from '@netfoundry-ui/page/userinfo';
import { AuthenticationGuard, AuthorizationResolveService } from '@netfoundry-ui/shared/authorization';
import { GrowlerErrorHistoryComponent } from '@netfoundry-ui/shared/growler';
import { GettingStartedComponent, SampleServiceComponent } from '@netfoundry-ui/feature/getting-started';
import { SignupCompleteComponent, SignupReturnComponent } from '@netfoundry-ui/feature/views/signup';
import { AuthPoliciesComponent, PageAuthPoliciesModule } from '@netfoundry-ui/page/auth-policies';
import { JwtSignersComponent } from '@netfoundry-ui/page/jwt-signers';
import {
  DeactivateGuardService,
  EdgeRoutersPageComponent,
  IdentitiesPageComponent,
  ServicesPageComponent,
  ConfigurationsPageComponent,
  ConfigurationFormComponent,
  ServicePoliciesPageComponent,
  NetworkVisualizerComponent,
  EdgeRouterPoliciesPageComponent,
  IdentityFormComponent,
  EdgeRouterFormComponent,
  ServiceFormComponent,
  SimpleServiceComponent,
  CardListComponent,
  ServicePolicyFormComponent,
  EdgeRouterPolicyFormComponent,
  ServiceEdgeRouterPoliciesPageComponent,
  ServiceEdgeRouterPolicyFormComponent,
  TerminatorsPageComponent,
  TerminatorFormComponent,
  JwtSignersPageComponent,
  JwtSignerFormComponent,
  AuthPoliciesPageComponent,
  AuthPolicyFormComponent
} from 'ziti-console-lib';
import { EdgeRoutersExtensionComponent } from '@netfoundry-ui/page/edge-routers-extension';
import { IdentitiesExtensionComponent } from  '@netfoundry-ui/page/identities-extension';

import { environment } from '../environments/environment';

const defaultRoutes: Routes = [
    {
        path: 'integrations',
        loadChildren: () => import('@netfoundry-ui/page/subscriptions').then((m) => m.PageSubscriptionsModule),
    },
    { path: 'app-subscriptions', redirectTo: '/integrations/endpoint-lifecycle', pathMatch: 'full' },
    { path: 'virtual-wan', redirectTo: '/integrations/virtual-wan', pathMatch: 'full' },
    { path: 'client-synchronization', redirectTo: '/integrations/aad', pathMatch: 'full' },

    {
        path: 'v7/dashboard',
        redirectTo: '/infrastructure/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        redirectTo: '/network/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'main',
        component: DashboardComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-main', redirectTo: '/main', pathMatch: 'full' },

    { path: 'login', component: LoginComponent },
    { path: 'app-login', redirectTo: '/login', pathMatch: 'full' },
    { path: 'vanity-logout', redirectTo: '/login?vanityLogout=true', pathMatch: 'full' },

    {
        path: 'user-identities',
        loadChildren: () => import('@netfoundry-ui/page/identities').then((m) => m.PageIdentitiesModule),
    },
    { path: 'identities/users', redirectTo: '/user-identities/users', pathMatch: 'full' },
    { path: 'identities/user-roles', redirectTo: '/user-identities/user-roles', pathMatch: 'full' },
    { path: 'user-roles', redirectTo: '/user-identities/user-roles', pathMatch: 'full' },
    { path: 'app-users', redirectTo: '/user-identities/app-users', pathMatch: 'full' },
    { path: 'identities/tokens', redirectTo: '/user-identities/tokens', pathMatch: 'full' },
    { path: 'identities/invitations', redirectTo: '/user-identities/invitations', pathMatch: 'full' },

    {
        path: 'reporting',
        loadChildren: () => import('@netfoundry-ui/page/reporting').then((m) => m.PageReportingModule),
    },
    { path: 'dailystats', redirectTo: '/reporting', pathMatch: 'full' },
    { path: 'network-group-summary', redirectTo: '/reporting', pathMatch: 'full' },
    { path: 'daily-endpoint-utilization', redirectTo: '/reporting', pathMatch: 'full' },

    {
        path: 'profile',
        component: UserinfoComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-profile', redirectTo: '/profile', pathMatch: 'full' },

    {
        path: 'user-settings',
        component: UserSettingsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-user-settings', redirectTo: '/user-settings', pathMatch: 'full' },

    {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-logs', redirectTo: '/logs', pathMatch: 'full' },
    {
        path: 'browzer-routers',
        loadChildren: () => import('@netfoundry-ui/page/ziti-edge-routers').then((m) => m.PageZitiEdgeRoutersModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'browzer-cert-auth',
        redirectTo: 'certificate-authorities',
        pathMatch: 'full',
    },
    {
        path: 'management-events',
        loadChildren: () => import('@netfoundry-ui/page/events').then((m) => m.PageEventsModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'network-events',
        loadChildren: () =>
            import('@netfoundry-ui/page/network-events-metrics-tab').then((m) => m.PageNetworkEventsMetricsTabModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'network-controllers',
        loadChildren: () =>
            import('@netfoundry-ui/page/network-controllers').then((m) => m.PageNetworkControllersModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'allocated-ips',
        loadChildren: () => import('@netfoundry-ui/page/allocated-ips').then((m) => m.PageAllocatedIpsModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'stored-secrets',
        loadChildren: () => import('@netfoundry-ui/page/stored-secrets').then((m) => m.PageStoredSecretsModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-events', redirectTo: '/events', pathMatch: 'full' },

    {
        path: 'alarms',
        loadChildren: () => import('@netfoundry-ui/page/alarms').then((m) => m.PageAlarmsModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-alarms', redirectTo: '/alarms', pathMatch: 'full' },

    { path: 'callback', component: CallbackComponent },

    { path: 'invitation', component: InvitationComponent },
    { path: 'app-invitation', redirectTo: '/invitation', pathMatch: 'full' },

    { path: 'signup', component: SelfserviceComponent, canActivate: [RedirectGuard] },
    { path: 'trial', component: SelfserviceComponent },
    { path: 'selfservice', component: SelfserviceComponent },
    {
        path: 'error-history',
        component: GrowlerErrorHistoryComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'page-not-found',
        loadChildren: () => import('@netfoundry-ui/page/page-not-found').then((m) => m.PagePageNotFoundModule),
    },
    {
        path: 'dashboard', redirectTo: '/network/dashboard', pathMatch: 'full'
    },
    {
        path: 'v7/endpoints',
        redirectTo: 'identities',
        pathMatch: 'full',
    },
    {
        path: 'identities',
        component: IdentitiesExtensionComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'identities/:id',
        component: IdentityFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'jwt-signers',
        component: JwtSignersPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'jwt-signers/:id',
        component: JwtSignerFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
      path: 'auth-policies',
      component: AuthPoliciesPageComponent,
      canActivate: [AuthenticationGuard],
      canDeactivate: [DeactivateGuardService],
      resolve: { authorization: AuthorizationResolveService },
    },
    {
      path: 'auth-policies/:id',
      component: AuthPolicyFormComponent,
      canActivate: [AuthenticationGuard],
      canDeactivate: [DeactivateGuardService],
      resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/connections/services',
        redirectTo: 'services',
        pathMatch: 'full',
    },
    {
        path: 'services',
        component: ServicesPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'services/simple',
        component: SimpleServiceComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'services/select',
        component: CardListComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'services/advanced',
        redirectTo: 'services/advanced/create',
        pathMatch: 'full'
    },
    {
        path: 'services/advanced/:id',
        component: ServiceFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/routers',
        redirectTo: 'infrastructure/routers',
        pathMatch: 'full',
    },
    {
        path: 'routers',
        component: EdgeRoutersPageComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'routers/:id',
        component: EdgeRoutersExtensionComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'transit-routers',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'configs',
        component: ConfigurationsPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'configs/:id',
        component: ConfigurationFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'recipes',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
      path: 'terminators',
      component: TerminatorsPageComponent,
      canActivate: [AuthenticationGuard],
      canDeactivate: [DeactivateGuardService],
      resolve: { authorization: AuthorizationResolveService },
    },
    {
      path: 'terminators/:id',
      component: TerminatorFormComponent,
      canActivate: [AuthenticationGuard],
      canDeactivate: [DeactivateGuardService],
      resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/connections/appwans',
        redirectTo: 'service-policies',
        pathMatch: 'full',
    },
    {
        path: 'service-policies',
        component: ServicePoliciesPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'service-policies/:id',
        component: ServicePolicyFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/policies',
        redirectTo: 'router-policies',
        pathMatch: 'full',
    },
    {
        path: 'router-policies',
        component: EdgeRouterPoliciesPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'router-policies/:id',
        component: EdgeRouterPolicyFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'service-router-policies',
        component: ServiceEdgeRouterPoliciesPageComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'service-router-policies/:id',
        component: ServiceEdgeRouterPolicyFormComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'posture-checks',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'certificate-authorities',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'login',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'profile',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'servers',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'sessions',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'api-sessions',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'settings',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'config-types',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing',
        loadChildren: () => import('@netfoundry-ui/page/billing').then((m) => m.PageBillingModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: '/billing/dashboard',
        loadChildren: () => import('@netfoundry-ui/page/billing').then((m) => m.PageBillingModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: '/organization/billing',
        loadChildren: () => import('@netfoundry-ui/page/billing').then((m) => m.PageBillingModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: '/support/billing',
        loadChildren: () => import('@netfoundry-ui/page/billing').then((m) => m.PageBillingModule),
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'payment-profile',
        loadChildren: () => import('@netfoundry-ui/page/payment-profile').then((m) => m.PagePaymentProfileModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'account-details',
        loadChildren: () => import('@netfoundry-ui/page/account-details').then((m) => m.PageAccountDetailsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscriptions',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscriptions').then((m) => m.PageBillingSubscriptionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscription-overages',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscription-overages').then(
                (m) => m.PageBillingSubscriptionOveragesModule
            ),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscription-usage',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscription-usage').then((m) => m.PageBillingSubscriptionUsageModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing/payment-profile',
        loadChildren: () => import('@netfoundry-ui/page/payment-profile').then((m) => m.PagePaymentProfileModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing/account-details',
        loadChildren: () => import('@netfoundry-ui/page/account-details').then((m) => m.PageAccountDetailsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing/billing-subscriptions',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscriptions').then((m) => m.PageBillingSubscriptionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing/billing-subscription-overages',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-overages').then(
            (m) => m.PageBillingSubscriptionOveragesModule
          ),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing/billing-subscription-usage',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-usage').then((m) => m.PageBillingSubscriptionUsageModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization/payment-profile',
        loadChildren: () => import('@netfoundry-ui/page/payment-profile').then((m) => m.PagePaymentProfileModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization/account-details',
        loadChildren: () => import('@netfoundry-ui/page/account-details').then((m) => m.PageAccountDetailsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization/billing-subscriptions',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscriptions').then((m) => m.PageBillingSubscriptionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization/billing-subscription-overages',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-overages').then(
            (m) => m.PageBillingSubscriptionOveragesModule
          ),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organization/billing-subscription-usage',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-usage').then((m) => m.PageBillingSubscriptionUsageModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'support/payment-profile',
        loadChildren: () => import('@netfoundry-ui/page/payment-profile').then((m) => m.PagePaymentProfileModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'support/account-details',
        loadChildren: () => import('@netfoundry-ui/page/account-details').then((m) => m.PageAccountDetailsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'support/billing-subscriptions',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscriptions').then((m) => m.PageBillingSubscriptionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'support/billing-subscription-overages',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-overages').then(
            (m) => m.PageBillingSubscriptionOveragesModule
          ),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'support/billing-subscription-usage',
        loadChildren: () =>
          import('@netfoundry-ui/page/billing-subscription-usage').then((m) => m.PageBillingSubscriptionUsageModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organizations',
        loadChildren: () => import('@netfoundry-ui/page/organizations').then((m) => m.PageOrganizationsModule),
    },
    { path: 'settings', redirectTo: '/organizations/settings', pathMatch: 'full' },
    { path: 'app-settings', redirectTo: '/organizations/settings', pathMatch: 'full' },
    {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'notifications',
        loadChildren: () => import('@netfoundry-ui/page/notifications').then((m) => m.PageNotificationsModule),
    },
    {
        path: 'network-groups',
        component: NetworkGroupsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'process-executions',
        loadChildren: () => import('@netfoundry-ui/page/process-executions').then((m) => m.PageProcessExecutionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'attributes-explorer',
        loadChildren: () =>
            import('@netfoundry-ui/page/attributes-explorer').then((m) => m.PageAttributesExplorerModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'infrastructure/routers', redirectTo: 'network/edge-routers', pathMatch: 'full'
    },
    {
        path: 'network/edge-routers',
        loadChildren: () => import('@netfoundry-ui/page/ziti-edge-routers').then((m) => m.PageZitiEdgeRoutersModule),
    },
    { path: 'zrouters', redirectTo: 'infrastructure/routers', pathMatch: 'full' },

    {
        path: 'infrastructure/dashboard', redirectTo: 'network/dashboard', pathMatch: 'full'
    },
    {
        path: 'network/dashboard',
        component: ZitiDashboardComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'zdashboard', redirectTo: 'infrastructure/dashboard', pathMatch: 'full' },

    {
        path: 'infrastructure/certificate-authorities',
        loadChildren: () =>
            import('@netfoundry-ui/page/ziti-certificate-authorities').then(
                (m) => m.PageZitiCertificateAuthoritiesModule
            ),
    },
    {
        path: 'browzer-apps',
        loadChildren: () => import('@netfoundry-ui/page/browzer').then((m) => m.PageBrowzerModule),
    },
    {
        path: 'browzer-bootstrappers',
        loadChildren: () => import('@netfoundry-ui/page/browzer-bootstrappers').then((m) => m.PageBrowzerBootstrappersModule),
    },
    { path: 'infrastructure/network-groups', redirectTo: 'network-groups', pathMatch: 'full' },
    {
        path: 'networks',
        component: NetworksCombinedComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'networks-combined', redirectTo: 'networks', pathMatch: 'full' },
    { path: 'infrastructure/networks', redirectTo: 'networks', pathMatch: 'full' },
    {
        path: 'network-visualizer',
        component: NetworkVisualizerComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [DeactivateGuardService],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/topology',
        loadChildren: () => import('@netfoundry-ui/page/topology').then((m) => m.PageTopologyModule),
    },
    { path: 'topology', redirectTo: 'v7/topology', pathMatch: 'full' },

    {
        path: 'infrastructure/metrics',
        component: MetricsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'metrics', redirectTo: 'infrastructure/metrics', pathMatch: 'full' },
    {
        path: 'getting-started',
        component: GettingStartedComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'getting-started/sample-service',
        component: SampleServiceComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'signup-complete',
        component: SignupCompleteComponent,
    },
    {
        path: 'signup-return',
        component: SignupReturnComponent,
    },
    // NOTE: any new route must be added before this route page not found route
    { path: '**', redirectTo: 'page-not-found', resolve: { authorization: AuthorizationResolveService } },
];
//if lower only, add to the array, don't define separate array

export const routes: Routes = defaultRoutes;

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    providers: [AuthenticationGuard, AuthorizationResolveService],
})
export class AppRoutingModule {}
