import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {
    ApiService,
    FeatureService,
    INFRASTRUCTURE_URLS,
    MenuService,
    URLS,
    ZITI_URLS,
} from '@netfoundry-ui/shared/services';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { defer, delay } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '@netfoundry-ui/shared/authorization';
import { Subscription } from 'rxjs';
import {
    BILLING_NAVIGATOR,
    BROWZER_NAVIGATOR,
    NETWORKS_NAVIGATOR,
    ORGANIZATION_NAVIGATOR,
    SUPPORT_NAVIGATOR,
    ZAC_NAVIGATOR,
} from '@netfoundry-ui/feature/navigator';
import { AccountService } from '@netfoundry-ui/feature/shared-services';
import {ZitiControllerService} from "@netfoundry-ui/shared/apiv2";
import {ZITI_DOMAIN_CONTROLLER} from "ziti-console-lib";

import {isEmpty, last} from 'lodash';

@Component({
    selector: 'netfoundry-ui-app-switcher',
    templateUrl: './app-switcher.component.html',
    styleUrls: ['./app-switcher.component.scss'],
})
export class AppSwitcherComponent {
    @Output() showGrowlersEvent: EventEmitter<boolean> = new EventEmitter();
    showAppMenu = false;
    selectedApp = '';
    popup = '';
    confirmDialog: any;
    area = '';
    navIconClass = '';
    subscription = new Subscription();
    billingStatusInvalid = false;
    username: any = '';
    photo: any;
    BROWZER = URLS.BROWZER;
    enterpriseBilling = false;
    currentNetwork: any = {};
    currentNetworkGroup: any = {};
    currentAccount: any = {};
    currentNetworkGroups = [];
    cloudZitiNavigators = [
        ZAC_NAVIGATOR,
        ORGANIZATION_NAVIGATOR,
        BILLING_NAVIGATOR,
        BROWZER_NAVIGATOR,
        SUPPORT_NAVIGATOR,
    ];
    mopNavigators = [NETWORKS_NAVIGATOR, ORGANIZATION_NAVIGATOR, SUPPORT_NAVIGATOR];

    url = '';
    pageNamePath: any = undefined;

    appNameMap = {
      infrastructure: 'Network',
      networks: 'Network',
      billing: 'Billing',
      organization: 'Organization',
      browzer: 'BrowZer',
      support: 'Support'
    };

    constructor(
        private apiService: ApiService,
        private dialogForm: MatDialog,
        private loginService: LoginService,
        private menuService: MenuService,
        private router: Router,
        private featureService: FeatureService,
        private accountService: AccountService,
        @Inject(ZITI_DOMAIN_CONTROLLER) private controllerService: ZitiControllerService,
    ) {}

    ngOnInit() {
        this.username = localStorage.getItem('profile_nick');
        this.photo = localStorage.getItem('profile_picture');
        this.subscription.add(
            this.apiService.billingStatusInvalid.subscribe((status: boolean) => {
                this.billingStatusInvalid = status;
            })
        );

        this.subscription.add(
            this.accountService.currentAccount.subscribe((account) => {
                this.currentAccount = account;
                this.enterpriseBilling = account?.billingMode === 'Enterprise';
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = network;
            })
        );
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url.split('?')[0];
                this.updateBreadcrumbPath();
                this.updateSelectedApp();
            }
        });
        this.subscription.add(
          this.apiService.currentNetworkGroup.subscribe((networkGroup) => {
            this.currentNetworkGroup = networkGroup;
          })
        );
        this.url = this.router.url.split('?')[0];
        this.menuService.appChanged.subscribe((app) => {
            this.selectedApp = app;
        });
        defer(() => {
            this.updateSelectedApp();
            this.updateBreadcrumbPath();
        });
    }

    open(area: string) {
        if (area === 'Alerts') {
            this.showGrowlersEvent.emit();
            this.menuService.showAlerts();
        } else if (area === 'Profile') {
            this.popup = 'profile';
        } else if (area === 'Support') {
            this.router.navigate([URLS.GETTING_STARTED]);
            this.menuService.setArea('Support');
        }
    }

    openAppMenu() {
        this.showAppMenu = !this.showAppMenu;
    }

    updateBreadcrumbPath() {
      const splitUrl = this.router?.url?.split('/');
      const lastRouteElement = last(splitUrl);
      if (lastRouteElement) {
        this.pageNamePath = lastRouteElement;
      }
    }

    updateSelectedApp() {
        let navigators = this.mopNavigators;
        if (this.featureService.isCloudZiti) {
            navigators = this.cloudZitiNavigators;
        }
        let selectedApp;
        let selectedNavItem;
        navigators.forEach((navigator: any) => {
            navigator.groups.forEach((group: any) => {
                group.menuItems.forEach((menuItem: any) => {
                    if (!menuItem.selectedRoutes) {
                        return;
                    }
                    menuItem.selectedRoutes.forEach((route: any) => {
                        const urlSegments = this.url.split('/').filter((segment: string) => {
                          return !isEmpty(segment);
                        });
                        const routeSegments = route.split('/').filter((segment: string) => {
                          return !isEmpty(segment);
                        });
                        if (routeSegments[0] === urlSegments[0]) {
                            selectedApp = navigator.app;
                            selectedNavItem = menuItem;
                        }
                    });
                });
            });
        });
        if (selectedApp) {
            this.menuService.setApp(selectedApp);
        } else {
            this.menuService.setApp('mop');
        }
        if (!selectedNavItem) {
            selectedNavItem = this.menuService.DEFAULT_NAV_ITEM;
        }
        this.menuService.setNavItem(selectedNavItem);
    }

    appSelected(app: string, navRoute?: any) {
        switch (app) {
            case 'infrastructure':
                if (navRoute) {
                    this.router.navigate([navRoute]);
                } else {
                    this.router.navigate([INFRASTRUCTURE_URLS.DASHBOARD]);
                }
                this.menuService.setArea('ZAC');
                break;
            case 'networks':
                if (!this.currentNetwork?.id || this.controllerService.loadingZitiController || !this.controllerService.hasZitiSession) {
                    return;
                }
                this.router.navigate([INFRASTRUCTURE_URLS.DASHBOARD]);
                this.menuService.setArea('ZAC');
                break;
            case 'billing':
                if (!this.currentAccount?.id) {
                    return;
                }
                if (this.enterpriseBilling) {
                    this.router.navigate([URLS.BILLING_PAYMENT_PROFILE]);

                } else {
                    this.router.navigate([URLS.BILLING_DASHBOARD]);
                }
                this.menuService.setArea('Billing');
                break;
            case 'organization':
                this.router.navigate([URLS.USERS]);
                this.menuService.setArea('Organization');
                break;
            case 'browzer':
                this.router.navigate([URLS.BROWZER_APPS]);
                this.menuService.setArea('Browzer');
                break;
            case 'support':
                this.menuService.setArea('Support');
                break;
            case 'mop':
              this.menuService.setArea('MOP');
              break;
        }
        this.selectedApp = app;
        this.showAppMenu = false;
        defer(() => {
          if (!isEmpty(app)) {
            this.menuService.setApp(app);
          } else {
            this.menuService.setApp('mop');
          }
        });
    }

    hover(id: string) {
        this.popup = id;
    }

    toggleAppMenu() {
        const show = !this.showAppMenu;
        defer(() => {
            this.showAppMenu = show;
        });
        //this.menuService.resetArea();
    }

    closeAppMenu() {
        this.showAppMenu = false;
    }

    confirmLogout() {
        const data = {
            title: 'Log out of your account',
            appendId: 'Logout',
            subtitle: 'To continue, please tap the "Log out" button.',
            icon: 'Logout',
            action: 'LOG OUT',
            hideCancelOnConfirm: false
        };
        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '225px',
            width: '600px',
            autoFocus: false,
        });
        this.confirmDialog.beforeClosed().subscribe((result: any) => {
            // if the result has a property loggingOut, rather than being just a boolean value, the user is being
            //  logged out of the console and we should close the dialog without continuing
            if (result === undefined || result === false) {
                this.confirmed(false);
            } else if (result['loggingOut'] === undefined) {
                if (this.area !== '') {
                    this.open(this.area);
                }
                this.confirmed(result);
            }
        });
    }

    confirmed(event: any) {
        if (event) {
            const data = {
                title: 'Logging Out',
                appendId: 'Logout',
                subtitle: 'Please wait while you are logged out of the system...',
                icon: 'Logout',
                hideCancelLink: true
            };
            this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '225px',
                width: '600px',
                autoFocus: false,
            });
            delay(() => {
                this.loginService.logout(false);
            }, 100);
        }
    }

    get networkAppTooltip() {
      let tooltip = '';
      if (!this.currentNetwork?.id) {
          tooltip = 'Must select or create a network';
      } else if (this.controllerService.loadingZitiController) {
          tooltip = 'Waiting to establish session with Ziti controller...';
      } else if (!this.controllerService.hasZitiSession) {
          tooltip = 'Unable to establish valid session with selected network...'
      }
      return tooltip;
    }

    goToNetworkGroupsPage(event: any) {
      this.router.navigate([INFRASTRUCTURE_URLS.NETWORK_GROUPS]);
    }

    goToNetworksPage(event: any) {
        this.router.navigate([INFRASTRUCTURE_URLS.NETWORKS]);
    }

    goToPage(event: any) {
      switch (this.selectedApp) {
        case 'networks':
          this.router.navigate([INFRASTRUCTURE_URLS.NETWORKS]);
          break;
        case 'organization':
          this.router.navigate([URLS.USERS]);
          break;
        case 'billing':
          this.router.navigate([URLS.BILLING_PAYMENT_PROFILE]);
          break;
        case 'browzer':
          this.router.navigate([URLS.BROWZER_APPS]);
          break;
      }
    }

    goToNetworkDashboard(event: any) {
      this.router.navigate([INFRASTRUCTURE_URLS.DASHBOARD]);
    }
}
