import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { ZitiResourceHelper } from '@netfoundry-ui/feature/ziti-resource-helper';
import { NETWORK_SERVICE, NetworkServiceV2, ProviderService, RegionService, StoredSecretService } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, Network, NetworkGroup, NetworkV2 } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    HTTP_CLIENT,
    LocationService,
    LoggerService,
    NetworkGroupService,
    NetworkService,
    ValidateService,
    URLS,
    INFRASTRUCTURE_URLS
} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import * as yaml from 'js-yaml';
import _ from 'lodash';
import { FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { FileSaverService } from 'ngx-filesaver';
import { take } from 'rxjs/operators';

const DEFAULT_LOCATION_CODE = 'us-east-1';
const DEFAULT_AWS_LOCATION_CODE = 'us-east-1';
const DEFAULT_OCI_LOCATION_CODE = 'us-ashburn-1';
declare const gtag;

@Component({
    selector: 'app-v2-network-creation-form',
    templateUrl: './v2-network-creation-form.component.html',
    styleUrls: ['./v2-network-creation-form.component.scss'],
})
export class V2NetworkCreationFormComponent implements OnInit {
    fileInput: ElementRef;
    @ViewChild('saveNetworkAsCode', { static: true }) saveNetworkAsCode;
    // create an empty uploader until we have an actual URL
    uploader: FileUploader;
    fileUpload;
    fileIsReady = false;
    fileIsValid = false;
    selectedFile;
    fileObj;
    fileType = 'json';

    businessKey;
    form: FormGroup;
    public networkModel: NetworkV2 = new NetworkV2();
    enableAltDomain = false;
    servicesInvalid = false;
    endpointsInvalid = false;
    appWANsInvalid = false;
    edgeRoutersInvalid = false;
    edgeRouterPoliciesInvalid = false;
    postureChecksInvalid = false;
    certificateAuthoritiesInvalid = false;
    resourceData = {};
    currentNetworkGroup;
    processing = false;
    isEditing = false;
    isEditingResource = false;
    importError = false;
    importing = false;
    isHosted = true;
    organizationsList: NetworkGroup[] = [];
    editEnabled = false;
    regions: Location[] = [];
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    errorNameContent = false;
    errorLocation = false;
    errorProvider = false;
    locations = [];
    providers = [];
    keys = [];
    datacenters = [];
    provider;
    selection = 'netfoundryHosted';
    errors = {};
    errorNetworkType = false;
    editId = '';
    hideHelp = false;
    step = 0;
    isInline = false;
    errorAlternateDomainName = false;
    errorNetworkGroup = false;
    networkVersion = 7;
    networkGroupId;
    formDialogRef;
    networkGroupList = [];
    networkGroupMap = {};
    resourceTypes = ['AppWANs', 'Services', 'Endpoints', 'Edge Routers', 'Edge Router Policies', 'Posture Checks'];
    displayedRegions = [];
    storedSecretList = [];
    isSelfService;
    isSelfServiceGrowthTier;
    newSelfService = false;
    isNotEnterprise;
    customerProviderDetails;
    isEnterprise;
    private isHiding = false;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private apiService: ApiService,
        private cookieService: CookieService,
        private logger: LoggerService,
        private organizationService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        private growlerService: GrowlerService,
        private networkService: NetworkService,
        private regionService: RegionService,
        private providerService: ProviderService,
        private storedSecretService: StoredSecretService,
        private validateService: ValidateService,
        private fileSaverService: FileSaverService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private formBuilder: FormBuilder,
        public dialogForm: MatDialog,
        private billingService: BillingService,
        private billingEnabledService: BillingEnabledService,
        private authorizationService: AuthorizationService,
        private activatedRoute: ActivatedRoute,
        public featureService: FeatureService,
        private zitiResourceHelper: ZitiResourceHelper,
        private locationService: LocationService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {

    }

    @ViewChild('fileInput', { static: false }) set content(content: ElementRef) {
        if (content) {
            // initially setter gets called with undefined
            this.fileInput = content;
        }
    }

    async ngOnInit() {
        this.initModel();

        // initialize file upload form
        this.form = this.formBuilder.group({
            networkId: '',
            file: null,
        });

        this.apiService.currentTenant.pipe(take(1)).subscribe((tenant) => {
            this.newSelfService = localStorage.getItem(`nf_new_self_service_${tenant?.name}`) === 'true';
            this.apiService.currentSubscription.subscribe((subscription) => {
                const isSelfService = this.featureService.isSelfServiceAccount(subscription);
                this.isSelfServiceGrowthTier = this.featureService.isSelfServiceGrowthTier(subscription);
                this.isSelfService = isSelfService || this.newSelfService;
            });
        });

        // initialize the uploader for the drag and drop
        this.uploader = new FileUploader({ url: '' });
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['version']) {
                this.networkVersion = parseInt(params['version'], 10);
            }
        });

        // getting all the network groups the user has access to
        this.organizationService
            .get()
            .pipe(take(1))
            .subscribe((res) => {
                // storing them in a list
                for (const org of res) {
                    const organization = new NetworkGroup(org);
                    if (this.authorizationService.canCreateNetworks(organization.id)) {
                        this.networkGroupMap[organization.id] = organization;
                        this.networkGroupList.push(organization);

                    }
                }

                // if the user only has access to one network group
                if (this.networkGroupList.length > 1) {
                    this.networkGroupList = this.sortByPipe.transform(this.networkGroupList, 'name', 'desc');
                }
            });

        this.apiService.currentNetworkGroup.subscribe((networkGroup) => {
            this.currentNetworkGroup = networkGroup;
            this.networkGroupId = this.currentNetworkGroup.getId();

            // upload URL
            const uploadOptions = {
                url: this.currentNetworkGroup.getSelfLink() + '/networks',
                authToken: 'Bearer ' + localStorage.getItem('access_token'),
                additionalParameter: {},
            };
            this.uploader = new FileUploader(uploadOptions);
        });

        this.providerService.getProviderPage().then((providers) => {
            if (this.isSelfService || this.isSelfServiceGrowthTier) {
                this.providers = providers.filter((provider) => provider.type === 'AWS');
                this.networkModel.provider = 'AWS';
                this.networkModel.locationCode = DEFAULT_AWS_LOCATION_CODE;
            } else {
                this.providers = providers.filter((provider) => provider.type === 'OCI' || provider.type === 'AWS');
                this.networkModel.provider = 'AWS';
                this.networkModel.locationCode = DEFAULT_AWS_LOCATION_CODE;
            }

            this.regionService.findRegionsByProvider([this.networkModel.provider]).subscribe((regions) => {
                this.displayedRegions = regions;
            });
        });
        this.networkModel.publicCertEnabled = false;
        this.networkModel.customerProviderDetails = {};
        if (this.isSelfService || this.isSelfServiceGrowthTier) {
            this.isNotEnterprise = true;
        } else {
            this.isEnterprise = true;
        }
    }

    async save() {
        if (await this.validate2()) {
            this.processing = true;
            this.networkModel.name = this.networkModel.name.trim();

            this.logger.info('Save Network Model', this.networkModel);
            this.logger.info('Selected Version', this.networkVersion);

            if (this.fileObj && this.fileIsReady && Number(this.networkVersion) === 7) {
                if (this.validate()) {
                    this.fileObj.name = this.networkModel.name;
                    this.fileObj.networkGroupId = this.networkGroupId;
                    this.createNetwork();
                } else {
                    this.processing = false;
                }
                return;
            }
            if (await this.confirmSave()) {
                if (Number(this.networkVersion) === 7) {
                    if (this.featureService.experimentalFeatures && this.fileIsReady) {
                        this.createNetwork();
                    } else {
                        this.saveV7();
                    }
                }
            } else {
                this.close();
            }
        }
    }

    toggleAlternateDomain() {
        this.enableAltDomain = !this.enableAltDomain;
    }

    toggleCertificate() {
        this.networkModel.publicCertEnabled = !this.networkModel.publicCertEnabled;
    }

    hide() {
        this.dialogRef.close();
    }

    close(returnVal?) {
        this.dialogRef.close(returnVal);
    }

    trackById(index, item) {
        return item.id;
    }

    toggleEditor() {
        this.isEditing = !this.isEditing;
        this.isEditingResource = false;
    }

    toggleResourceEditor(resourceType) {
        const resources = this.getResources(resourceType);
        this.resourceData = { resources, resourceType };
        this.isEditingResource = !this.isEditingResource;
    }

    doneEditing(resourceData) {
        this.isEditingResource = false;
        this.setResources(resourceData.resources, resourceData.resourceType);
    }

    setResources(data, resourceType) {
        switch (resourceType) {
            case 'ziti-service':
                this.fileObj.services = data;
                break;
            case 'ziti-endpoint':
                this.fileObj.endpoints = data;
                break;
            case 'ziti-app-wan':
                this.fileObj.appWans = data;
                break;
            case 'edge-router':
                this.fileObj.edgeRouters = data;
                break;
            case 'edge-router-policy':
                this.fileObj.edgeRouterPolicies = data;
                break;
            case 'posture-check':
                this.fileObj.postureChecks = data;
                break;
        }
        this.fileIsValid = this.validateV7(this.fileObj);
    }

    changeStoredSecrets() {
        this.storedSecretService.getStoredSecretPage().then((storedSecrets) => {
            this.storedSecretList = storedSecrets.filter((storedSecret) => storedSecret.networkGroupId === this.networkGroupId)
        })
    }

    getResources(resourceType) {
        let resources = [];
        switch (resourceType) {
            case 'ziti-service':
                resources = this.fileObj.services;
                break;
            case 'ziti-endpoint':
                resources = this.fileObj.endpoints;
                break;
            case 'ziti-app-wan':
                resources = this.fileObj.appWans;
                break;
            case 'edge-router':
                resources = this.fileObj.edgeRouters;
                break;
            case 'edge-router-policy':
                resources = this.fileObj.edgeRouterPolicies;
                break;
            case 'posture-check':
                resources = this.fileObj.postureChecks;
                break;
        }
        return resources;
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('file').setValue(file);
            this.selectedFile = this.form.get('file').value;
            if (!this.selectedFile) {
                this.fileIsValid = false;
                return;
            }

            const fileNameComponents = this.selectedFile.name.split('.');
            this.fileType = fileNameComponents[fileNameComponents.length - 1];

            try {
                this.selectedFile.text().then((result) => {
                    let fileObj;
                    if (this.fileType === 'yml') {
                        fileObj = yaml.load(result);
                    } else {
                        fileObj = JSON.parse(result);
                    }
                    this.fileObj = fileObj;
                    this.fileIsValid = this.validateV7(this.fileObj);
                    this.fileIsReady = true;
                    if (!_.isEmpty(this.fileObj.name)) {
                        this.networkModel.name = this.fileObj.name;
                    } else {
                        this.fileObj.name = this.networkModel.name;
                    }
                    if (!_.isEmpty(this.fileObj.provider)) {
                        this.networkModel.provider = this.fileObj.provider;
                        this.providerChanged(this.fileObj.provider);
                    }
                    if (!_.isEmpty(this.fileObj.locationCode)) {
                        this.networkModel.locationCode = this.fileObj.locationCode;
                    }
                    if (!_.isEmpty(this.fileObj.networkGroupId)) {
                        this.networkGroupId = this.fileObj.networkGroupId;
                        this.networkModel['networkGroupId'] = this.fileObj.networkGroupId;
                    } else {
                        this.fileObj.networkGroupId = this.currentNetworkGroup.id;
                    }
                    if (this.fileIsValid) {
                        this.uploader.addToQueue([this.form.get('file').value]);
                    } else {
                        // show error/edit option
                    }
                });
            } catch (err) {
                this.importError = true;
                this.importing = false;
                this.growlerService.show(
                    new GrowlerData('error', `Network Import Failed `, 'Unable to process file upload.')
                );
            }
        }
    }

    fileDrop() {
        if (this.uploader.queue.length > 0) {
            this.fileIsReady = true;
        }
    }

    showDialog() {
        const event = new MouseEvent('click', { bubbles: true });
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    getRegionsByProvider(providers) {
        this.regionService.findRegionsByProvider(providers).subscribe((locations) => {
            this.locations = locations;
        });
    }

    providerChanged(provider) {
        this.regionService.findRegionsByProvider(provider).subscribe((regions) => {
            this.displayedRegions = regions;
            if (provider === 'OCI') {
                this.networkModel.locationCode = DEFAULT_OCI_LOCATION_CODE;
            }
            if (provider === 'AWS') {
                this.networkModel.locationCode = DEFAULT_AWS_LOCATION_CODE;
            }
        });
    }

    createNetwork() {
        if (!this.fileObj) {
            return;
        }

        if (gtag)
            gtag('event', 'click', {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_category: 'network',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_label: 'createNetwork',
                value: JSON.stringify(this.fileObj),
            });
        this.networkServiceV2.createNetwork(this.fileObj, 'application/json').subscribe(
            () => {
                this.growlerService.show(new GrowlerData('success', 'Success', 'Network Import Started'));
                _.delay(() => {
                    this.hide();
                }, 500);
            },
            (error) => {
                this.processing = false;
                if (_.isString(error.error)) {
                    error.error = JSON.parse(error.error);
                }
                const errMsg = _.get(error, 'error.errors[0]', _.get(error, 'message', ''));
                this.growlerService.show(new GrowlerData('error', 'Network Import Failed ', errMsg));
            }
        );
    }

    download() {
        if (gtag)
            gtag('event', 'click', {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_category: 'network',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_label: 'downloadYAMLTemplate',
            });
        const templateFile = 'NetworkTemplate.yml';
        this.http
            .get(`/assets/data/${templateFile}`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe((res) => {
                this.fileSaverService.save(res.body, templateFile);
            });
    }

    disableCustomerHosted() {
        this.isHosted = true;
        this.networkModel.provider = 'AWS';
        this.validate2();
    }

    toggle() {
        this.isHosted = !this.isHosted;
    }

    disableNFHosted() {
        this.changeStoredSecrets();
        this.isHosted = false;
        this.networkModel.locationCode = undefined;
        this.networkModel.provider = 'CUSTOMER';
        this.networkModel.customerProviderDetails = {}
        this.validate2();
    }

    async validate2() {
        this.errors = {};
        this.networkModel.name = this.networkModel.name?.trim();
        this.networkModel.networkGroupId = this.networkGroupId;
        if(this.networkModel.provider !== 'CUSTOMER') {
            _.unset(this.networkModel, 'customerProviderDetails');
        }
        if(this.networkModel.provider === 'CUSTOMER') {
            this.networkModel.customerProviderDetails.managementPort = 8443;
            this.networkModel.customerProviderDetails.ipAddress = this.networkModel.customerProviderDetails.ipAddress?.trim();
        }

        if(!this.networkModel.id) {
            const errors = await this.networkServiceV2.validateCreate(this.networkModel);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.networkGroupId')) {
                this.errors['networkGroupId'] = _.find(errors, (e) => e.path === '$.networkGroupId').message;
            }
            if (_.find(errors, (e) => e.message.includes('Network limit exceeded.'))) {
                this.errors['networkLimit'] = _.find(errors, (e) => e.path.includes('networkGroupId')).message;
            }
            if (_.find(errors, (e) => e.path === '$.provider')) {
                this.errors['provider'] = _.find(errors, (e) => e.path === '$.provider').message;
            }
            if(this.networkModel.provider === 'CUSTOMER') {
                if (_.find(errors, (e) => e.path.includes('ipAddress'))) {
                    this.errors['ipAddress'] = _.find(errors, (e) => e.path.includes('ipAddress')).message;
                }

                if (_.find(errors, (e) => e.path.includes('sshAccessKeyPairName'))) {
                    this.errors['sshAccessKeyPairName'] = _.find(errors, (e) => e.path.includes('sshAccessKeyPairName')).message;
                }
            }
            if(this.networkModel.provider !== 'CUSTOMER') {
                if (_.find(errors, (e) => e.path === '$.region')) {
                    this.errors['region'] = _.find(errors, (e) => e.path === '$.region').message;
                }
            }
            return errors.length === 0;
        } else {
            const errors = await this.networkServiceV2.validateUpdate(this.networkModel.id, this.networkModel);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.networkGroupId')) {
                this.errors['networkGroupId'] = _.find(errors, (e) => e.path === '$.networkGroupId').message;
            }
            if (_.find(errors, (e) => e.path === '$.provider')) {
                this.errors['provider'] = _.find(errors, (e) => e.path === '$.provider').message;
            }
            if (_.find(errors, (e) => e.message.includes('Network limit exceeded.'))) {
                this.errors['networkLimit'] = _.find(errors, (e) => e.path.includes('networkGroupId')).message;
            }
            if(this.networkModel.provider === 'CUSTOMER') {
                if (_.find(errors, (e) => e.path.includes('ipAddress'))) {
                    this.errors['ipAddress'] = _.find(errors, (e) => e.path.includes('ipAddress')).message;
                }

                if (_.find(errors, (e) => e.path.includes('sshAccessKeyPairName'))) {
                    this.errors['sshAccessKeyPairName'] = _.find(errors, (e) => e.path.includes('sshAccessKeyPairName')).message;
                }
            }
            if(this.networkModel.provider !== 'CUSTOMER') {
                if (_.find(errors, (e) => e.path === '$.region')) {
                    this.errors['region'] = _.find(errors, (e) => e.path === '$.region').message;
                }
            }
            return errors.length === 0;
        }
    }

    validate(): boolean {
        this.errorName = false;
        this.errorNetworkGroup = false;
        this.errorNameLength = false;
        this.errorLocation = false;
        this.errorProvider = false;
        this.errorAlternateDomainName = false;

        if (!this.validateService.hasValue(this.networkVersion)) {
            this.errorNetworkGroup = true;
        }

        if (this.networkVersion === 7 && !this.validateService.hasValue(this.networkGroupId)) {
            this.errorNetworkGroup = true;
        } else if (this.networkVersion === 7 && !_.some(this.networkGroupList, { id: this.networkGroupId })) {
            this.errorNetworkGroup = true;
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Invalid',
                    'Invalid Network Group ',
                    'The network group provided in the upload file is invalid.' +
                        'It either does not exist or you do not have access to it.'
                )
            );
        }

        if (
            this.networkVersion === 7 &&
            this.enableAltDomain &&
            !this.validateService.isValidHostName(this.networkModel['alternateDomainName'])
        ) {
            this.errorAlternateDomainName = true;
        }
        if (
            this.networkVersion === 7 &&
            (!this.validateService.hasValue(this.networkModel['provider']) ||
                this.networkModel['provider'] === 'undefined')
        ) {
            this.errorProvider = true;
        }

        if (
            this.networkVersion === 7 && this.isHosted &&
            (!this.validateService.hasValue(this.networkModel['locationCode']) ||
                this.networkModel['locationCode'] === 'undefined')
        ) {
            this.errorLocation = true;
        }

        this.errorName = false;
        this.errorNameLength = false;
        this.errorNameContent = false;
        if (!this.fileIsReady || Number(this.networkVersion) === 7) {
            if (!this.validateService.hasValue(this.networkModel.name)) {
                this.errorName = true;
            } else if (_.size(this.networkModel.name) < 5) {
                this.errorNameLength = true;
            } else if (!this.validateService.isValidNetworkName(this.networkModel.name)) {
                this.errorNameContent = true;
            }
        }

        return (
            !this.errorName &&
            !this.errorLocation &&
            !this.errorProvider &&
            !this.errorNetworkGroup &&
            !this.errorNameLength &&
            !this.errorNameContent &&
            !this.errorAlternateDomainName
        );
    }

    validateV7(fileObj) {
        const {
            servicesInvalid,
            endpointsInvalid,
            appWANsInvalid,
            edgeRoutersInvalid,
            edgeRouterPoliciesInvalid,
            postureChecksInvalid,
        } = this.zitiResourceHelper.validateNetwork(fileObj);
        this.servicesInvalid = servicesInvalid;
        this.endpointsInvalid = endpointsInvalid;
        this.appWANsInvalid = appWANsInvalid;
        this.edgeRoutersInvalid = edgeRoutersInvalid;
        this.edgeRouterPoliciesInvalid = edgeRouterPoliciesInvalid;
        this.postureChecksInvalid = postureChecksInvalid;
        return (
            !servicesInvalid &&
            !endpointsInvalid &&
            !appWANsInvalid &&
            !edgeRoutersInvalid &&
            !edgeRouterPoliciesInvalid &&
            !postureChecksInvalid
        );
    }

    async confirmSave() {
        // whether or not billing is enabled
        let billingEnabled = false;

        // whether or not the network group is in a 7 day trial
        let isFourteenDayTrial = false;

        // if the network group selected is the same as the current network group

        if (this.networkGroupId === this.currentNetworkGroup?.id) {
            // billing enabled and seven day trial have already been determined, use the existing values
            billingEnabled = this.billingEnabledService.billingEnabled;
            isFourteenDayTrial = this.billingEnabledService.isFourteenDayTrial;
        } else {
            const selectedNetworkGroup = this.networkGroupMap[this.networkGroupId];
            // otherwise, check billing for the network group the user selected
            const billingCheck = await this.billingEnabledService.isBillingEnabledNetworkGroup(
                this.networkGroupId,
                selectedNetworkGroup['billingAccountId']
            );

            // set billingEnabled and isFourteenDayTrial based on the result
            billingEnabled = billingCheck.billingEnabled;
            isFourteenDayTrial = billingCheck.isFourteenDayTrial;
        }

        // if billing is enabled and there is not a 7 day trial
        if (
            billingEnabled &&
            !isFourteenDayTrial &&
            !(this.isSelfService || this.newSelfService) &&
            !this.authorizationService.isSupportUser()
        ) {
            const data = {
                title: 'Please Confirm you would like to add a Network',
                appendId: 'Networks',
                subtitle: `A charge of $${this.billingService.networkPricing} USD a month will be added to your next invoice.`,
                icon: 'AddaNetwork',
                action: 'Yes',
            };
            this.formDialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            const saveNetwork = await this.formDialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                    // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                    //  logged out of the console and we should close the dialog without continuing
                    if (result === undefined) {
                        return false;
                    } else if (result['loggingOut'] === undefined) {
                        return result;
                    }
                });
            return saveNetwork;
        } else if ((this.isSelfService || this.newSelfService) && !this.authorizationService.isSupportUser()) {
            let numberOfNetworks;
            if (this.networkModel['organizationId']) {
                numberOfNetworks = await this.getNumberOfNetworks();
            } else {
                numberOfNetworks = await this.getNumberOfNetworks();
            }

            if (numberOfNetworks !== 0) {
                const data = {
                    warning: true,
                    title: 'Unable to Add Additional Networks',
                    appendId: 'Networks',
                    subtitle: `You are limited to one network while using a Trial account.
                        Please contact support for more information about migrating to an "Enterprise" account.`,
                    action: 'OK',
                    icon: 'AddaNetwork',
                };

                this.formDialogRef = this.dialogForm.open(ConfirmComponent, {
                    data: data,
                    height: '340px',
                    width: '600px',
                    autoFocus: false,
                });
                return await this.formDialogRef
                    .afterClosed()
                    .toPromise()
                    .then(() => false);
            } else {
                return true;
            }
        } else if (isFourteenDayTrial) {
            let numberOfNetworks;
            if (this.networkModel['organizationId']) {
                numberOfNetworks = await this.getNumberOfNetworks();
            } else {
                numberOfNetworks = await this.getNumberOfNetworks();
            }

            if (numberOfNetworks !== 0) {
                const data = {
                    warning: true,
                    title: 'Unable to Add Additional Networks',
                    appendId: 'Networks',
                    subtitle:
                        'You are limited to one network during your seven day trial period. To add additional networks finish your signup by providing your credit card information',
                    action: 'OK',
                    icon: 'AddaNetwork',
                };

                this.formDialogRef = this.dialogForm.open(ConfirmComponent, {
                    data: data,
                    height: '340px',
                    width: '600px',
                    autoFocus: false,
                });
                return await this.formDialogRef
                    .afterClosed()
                    .toPromise()
                    .then(() => false);
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    async getNumberOfNetworks() {
        // let networkGroup = this.networkGroupMap[networkGroupId];
        const networkGroup = this.currentNetworkGroup;

        let v6NetworkCount = 0;
        let v7NetworkCount = 0;

        const v6NetworkProm = this.organizationService
            .getLinkedResources(networkGroup, 'networks')
            .toPromise()
            .then((result) => {
                v6NetworkCount = result.length;
            });

        const v7NetworkProm = this.networkServiceV2
            .findByNetworkGroupId(networkGroup.id)
            .toPromise()
            .then((result) => {
                v7NetworkCount = result.resources.length;
            });

        return await Promise.all([v6NetworkProm, v7NetworkProm]).then(() => v6NetworkCount + v7NetworkCount);
    }

    private initModel() {
            if (!this.networkModel.id) {
                this.networkModel = this.networkModel as NetworkV2
                this.networkModel.customerProviderDetails = {
                    sshAccessKeyPairName: '',
                    managmentPort: 8443,
                    ipAddress: ''
                }
            };
        }


    private saveV7() {
        this.networkModel.networkGroupId = this.networkGroupId;
        this.networkModel.size = this.environment.defaultSize;

        if (this.featureService.isCloudZiti) {
            this.networkModel.publicCertEnabled = true;
        }

        if (this.validate2()) {
            // this.networkModel.networkConfigMetadataId = environment.defaultNetworkConfigMetadataId;

            if (gtag)
                gtag('event', 'click', {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    event_category: 'network',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    event_label: 'create',
                    value: JSON.stringify(this.networkModel),
                });
            this.networkServiceV2.createResource({ body: this.networkModel }).subscribe(
                (data) => {
                    this.logger.info('Create network response: ', data);
                    this.apiService.setCurrentNetwork(data as NetworkV2);
                    this.processing = false;
                    const url = this.featureService.isCloudZiti ? URLS.PROCESS_EXECUTIONS : URLS.V7DASHBOARD;
                    this.router.navigate([url]);
                    this.dialogRef.close();
                },
                (error) => {
                    this.processing = false;
                    if (_.isString(error.error)) {
                        error.error = JSON.parse(error.error);
                    }
                    const errMsg = _.get(error, 'error.errors[0]', _.get(error, 'message', ''));
                    this.growlerService.show(
                        new GrowlerData('error', 'Network creation failed', 'Failed to create a new network', errMsg)
                    );
                }
            );
        } else {
            this.logger.info('Failed to validate V7 network');
            this.processing = false;
        }
    }

    get showPublicCertToggle() {
      return this.featureService.powerUserEnabled && !this.environment.v3Enabled;
    }
}
