<link
    *ngIf="brandingService.darkModeEnabled && isLoggedIn && isZACView"
    href="/assets/styles/dark.css"
    rel="stylesheet"
    type="text/css"
/>
<link
    *ngIf="brandingService.darkModeEnabled && isLoggedIn"
    href="/assets/styles/darkmode.css"
    rel="stylesheet"
    type="text/css"
/>
<div class="app-container" [ngClass]="{ loggedIn: isLoggedIn }">
    <netfoundry-ui-side-bar *ngIf="isLoggedIn" [hide]="hideSideBar" [isCloudZiti]="isZACView" [disableNavigation]="!zitiControllerService.hasZitiSession && isZACView"></netfoundry-ui-side-bar>
    <netfoundry-ui-app-switcher (mouseenter)="handleMouseOver($event)" *ngIf="isLoggedIn && !hideSideBar"> </netfoundry-ui-app-switcher>
    <main
        (mouseenter)="close()"
        [ngClass]="{
            zacView: isZACView,
            mopView: !isZACView,
            open: isNavOpen,
            shaded: isShaded,
            experimental: featureService.experimentalFeatures,
            newNav: true,
            noPad: !isLoggedIn || noPadding,
            fullScreen: hideSideBar,
            darkmode: brandingService.darkModeEnabled && isLoggedIn,
            cardWarning:
                isLoggedIn && !billingEnabledService.hideCardWarning && billingEnabledService.isFourteenDayTrial
        }"
    >
        <app-banner-message
            [isLoggedIn]="isLoggedIn"
            [message]="bannerMessage"
            [isVisible]="showBanner"
            [showSupportLink]="showSupportLink"
            [buttonText]="bannerButtonText"
            (buttonClicked)="bannerButtonClicked()"
            [showSpinner]="showBannerSpinner"
        ></app-banner-message>
        <div
            *ngIf="isLoggedIn && !billingEnabledService.hideCardWarning && billingEnabledService.isFourteenDayTrial"
            class="creditCardWarningContainer"
        >
            <app-credit-card-warning (close)="closeWarning()" class="creditCardWarning"></app-credit-card-warning>
        </div>

        <app-list-header
            *ngIf="isLoggedIn && isHeader"
            [actionTitle]="actionTitle"
            [breadcrumb]="breadcrumb"
            [hasAzureSubscription]="hasAzureSubscription"
            [network]="currentNetwork"
            [organization]="currentOrg"
            [resourceType]="resourceType"
            [shortBreadCrumbs]="shortBreadCrumbs"
            [tabs]="tabs"
            [title]="subtitle"
            [url]="url"
        ></app-list-header>
        <router-outlet></router-outlet>
        <div class="ziti-loading-mask" *ngIf="isZACView && zitiControllerService.loadingZitiController"></div>
        <app-loading-indicator *ngIf="zitiControllerService.loadingZitiController && isZACView " [isLoading]="zitiControllerService.loadingZitiController && isZACView"></app-loading-indicator>
    </main>
</div>
<app-recent-alerts [currentNetwork]="currentNetwork"></app-recent-alerts>
<netfoundry-ui-overlay></netfoundry-ui-overlay>
<lib-growler></lib-growler>
