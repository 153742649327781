import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    HTTP_CLIENT,
    LoggerService,
    NetworkGroupService,
    NetworkService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// constant for the location code. This should be removed when a selector is added
const locationCode = 'us-east-1';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
    @ViewChild('saveNetworkAsCode', { static: true }) saveNetworkAsCode;

    // create an empty uploader until we have an actual URL
    uploader: FileUploader;
    fileUpload;
    fileIsReady = false;
    businessKey;
    form: FormGroup;

    // input used for determining whether or not the esc button should be displayed
    // this is needed to show the create network screen without having to wait for the last network to finish deleting
    public networkModel: Network = new Network({});
    currentOrg: NetworkGroup = new NetworkGroup({});
    currentOrgId: string;
    errorName = false;
    errorNetworkGroup = false;
    errorNameLength = false;
    processing = false;
    networkGroupList = [];
    networkGroupMap = {};
    selectedNetworkGroup = new NetworkGroup({});
    hideNetworkGroupSelect = true;
    subscription: Subscription = new Subscription();
    hideHelp = false;
    formDialogRef;
    step = 0;
    private apiServiceSub: Subscription;
    private networkServiceSub: Subscription;

    constructor(
        private logger: LoggerService,
        private networkService: NetworkService,
        public dialogForm: MatDialog,
        private apiService: ApiService,
        private cookieService: CookieService,
        private dialogRef: MatDialogRef<WizardComponent>,
        private validateService: ValidateService,
        private organizationService: NetworkGroupService,
        private authorizationService: AuthorizationService,
        private sortByPipe: SortbyPipe,
        private billingEnabledService: BillingEnabledService,
        private billingService: BillingService,
        private growlerService: GrowlerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private fileSaverService: FileSaverService,
        private renderer: Renderer2,
        private formBuilder: FormBuilder,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        // initialize file upload form
        this.form = this.formBuilder.group({
            networkId: '',
            file: null,
        });

        // initialize the uploader for the drag and drop
        this.uploader = new FileUploader({ url: '' });
    }

    ngOnInit() {
        this.errorName = false;
        this.currentOrgId = this.cookieService.get('currentOrg');

        this.apiServiceSub = this.apiService.currentOrg.subscribe((org) => {
            this.currentOrg = new NetworkGroup(org);
            this.currentOrgId = this.currentOrg.getId();

            // upload URL
            const uploadOptions = {
                url: this.currentOrg.getSelfLink() + '/networks',
                authToken: 'Bearer ' + localStorage.getItem('access_token'),
                additionalParameter: {},
            };
            this.uploader = new FileUploader(uploadOptions);
        });

        // getting all the network groups the user has access to
        this.subscription.add(
            this.organizationService
                .get()
                .pipe(take(1))
                .subscribe((res) => {
                    // storing them in a list
                    for (const org of res) {
                        const organization = new NetworkGroup(org);
                        if (this.authorizationService.canCreateNetworks(organization.id)) {
                            this.networkGroupMap[organization.id] = organization;
                            this.networkGroupList.push(organization);
                        }
                    }

                    // if the user only has access to one network group
                    if (this.networkGroupList.length === 1) {
                        // hide the network group selection
                        this.hideNetworkGroupSelect = true;

                        // set the organization ID on the model based on the one network group
                        this.networkModel.organizationId = this.networkGroupList[0].getId();
                    } else {
                        this.hideNetworkGroupSelect = false;
                        this.networkGroupList = this.sortByPipe.transform(this.networkGroupList, 'name', 'desc');

                        // otherwise set the organization ID to an empty string to fix the placeholder
                        this.networkModel.organizationId = '';
                    }
                })
        );
    }

    ngOnDestroy() {
        this.apiServiceSub.unsubscribe();

        if (this.networkServiceSub != null) {
            this.networkServiceSub.unsubscribe();
        }

        this.subscription.unsubscribe();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.close();
        }
    }

    close(returnVal?) {
        this.dialogRef.close(returnVal);
    }

    async save() {
        if (this.validate()) {
            this.processing = true;
            if (this.fileIsReady) {
                this.addNetworkYML();
                return;
            }

            if (await this.confirmSave()) {
                // manage instance sizes - use small for non-prod environments, medium for prod environments.
                this.networkModel.networkConfigName = this.environment.defaultNetworkConfigName;

                // setting the location code using the constant. This should be removed when a location selector is added
                this.networkModel.locationCode = locationCode;
                this.networkService.save(this.networkModel, this.networkModel.organizationId).subscribe(
                    (data) => {
                        this.networkModel = new Network(data);
                        this.apiService.setCurrentNetwork(this.networkModel);
                        this.close({ network: this.networkModel });
                        this.processing = false;
                    },
                    (error) => {
                        this.processing = false;
                    }
                );
            } else {
                this.close();
            }
        }
    }

    async confirmSave() {
        // whether or not billing is enabled
        let billingEnabled = false;

        // whether or not the network group is in a 7 day trial
        let isFourteenDayTrial = false;

        // if the network group selected is the same as the current network group
        if (this.networkModel.organizationId === this.apiService.theOrgIs.getId()) {
            // billing enabled and seven day trial have already been determined, use the existing values
            billingEnabled = this.billingEnabledService.billingEnabled;
            isFourteenDayTrial = this.billingEnabledService.isFourteenDayTrial;
        } else {
            // otherwise, check billing for the network group the user selected
            const billingCheck = await this.billingEnabledService.isBillingEnabledNetworkGroup(
                this.selectedNetworkGroup.id,
                this.selectedNetworkGroup['billingAccountId']
            );

            // set billingEnabled and isFourteenDayTrial based on the result
            billingEnabled = billingCheck.billingEnabled;
            isFourteenDayTrial = billingCheck.isFourteenDayTrial;
        }
        // if billing is enabled and there is not a 7 day trial
        if (billingEnabled && !isFourteenDayTrial) {
            const data = {
                title: 'Please Confirm you would like to add a Network',
                appendId: 'Networks',
                subtitle: `A charge of $${this.billingService.networkPricing} USD a month will be added to your next invoice.`,
                icon: 'AddaNetwork',
                action: 'Yes',
            };
            this.formDialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            const saveNetwork = await this.formDialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                    // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                    //  logged out of the console and we should close the dialog without continuing
                    if (result === undefined) {
                        return false;
                    } else if (result['loggingOut'] === undefined) {
                        return result;
                    }
                });
            return saveNetwork;
        } else if (isFourteenDayTrial) {
            const numberOfNetworks = await this.getNumberOfNetworks(this.networkModel.organizationId);

            if (numberOfNetworks !== 0) {
                const data = {
                    warning: true,
                    title: 'Unable to Add Additional Networks',
                    appendId: 'Networks',
                    subtitle:
                        'You are limited to one network during your seven day trial period. To add additional networks finish your signup by providing your credit card information',
                    action: 'OK',
                    icon: 'AddaNetwork',
                };

                this.formDialogRef = this.dialogForm.open(ConfirmComponent, {
                    data: data,
                    height: '340px',
                    width: '600px',
                    autoFocus: false,
                });
                return await this.formDialogRef
                    .afterClosed()
                    .toPromise()
                    .then((result) => false);
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    validate(): boolean {
        this.errorName = false;
        this.errorNetworkGroup = false;
        this.errorNameLength = false;
        if (!this.validateService.hasValue(this.networkModel.organizationId)) {
            this.errorNetworkGroup = true;
        }

        if (!this.fileIsReady && !this.validateService.isValidNetworkName(this.networkModel.name)) {
            this.errorName = true;
        } else if (!this.validateService.isValidNetworkName(this.networkModel.name)) {
            this.errorNameLength = true;
        }

        return !this.errorName && !this.errorNetworkGroup;
    }

    async getNumberOfNetworks(networkGroupId) {
        const networkGroup = this.networkGroupMap[networkGroupId];

        return await this.organizationService
            .getLinkedResources(networkGroup, 'networks')
            .toPromise()
            .then((result) => result.length);
    }

    async addNetworkYML() {
        this.uploader.onBeforeUploadItem = (item) => {
            item.url = this.environment.apiUrl + '/organizations/' + this.networkModel.organizationId + '/networks';
        };

        // if we used the drag and drop
        if (this.uploader.queue.length > 0) {
            this.processing = true;
            const FileObject = this.uploader.queue[0];
            this.logger.info('Temp file', FileObject);
            this.logger.info('Adding file from queue...', FileObject._file);
            await FileObject.upload();
            this.fileUpload = FileObject;

            this.step = 1;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            FileObject.onComplete = (response: any, status: any, headers: any) => {
                if (status < 300) {
                    const networkAsCodeResponse = JSON.parse(response);
                    this.businessKey = networkAsCodeResponse['businessKey'];
                    this.networkModel = new Network(networkAsCodeResponse['network']);
                    this.logger.info('network as code upload process business key' + this.businessKey);

                    this.saveNetworkAsCode.fetchStatus();
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Network Template Upload Complete',
                            'Network template upload has been successful.'
                        )
                    );
                } else {
                    const error = JSON.parse(response);
                    this.logger.info('ERROR MESSAGE', error);
                    let errorMessage = '';
                    if (error != null && (error[0] != null || error['detail'] != null)) {
                        errorMessage = error[0] ? error[0]['message'] : error['detail'];
                    }

                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Network Template Upload Failed',
                            'Network template upload has failed. Import has been canceled. ' + errorMessage
                        )
                    );
                }

                this.processing = false;
                this.uploader.clearQueue();
                if (status >= 300) {
                    this.resetForm();
                }
            };
        } else {
            this.logger.error('No files in queue to upload');
            this.growlerService.show(
                new GrowlerData('error', 'Error', 'Appwan Template Upload Failed', 'No files queued to upload')
            );
        }
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.logger.info('Adding file...', file);
            this.form.get('file').setValue(file);
            this.fileIsReady = true;
            this.uploader.addToQueue([this.form.get('file').value]);
        }
    }

    fileDrop() {
        if (this.uploader.queue.length > 0) {
            this.fileIsReady = true;
        }
    }

    showDialog() {
        const event = new MouseEvent('click', { bubbles: true });
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    download() {
        const templateFile = 'NetworkTemplate.yml';
        this.http
            .get(`/assets/data/${templateFile}`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe((res) => {
                this.fileSaverService.save(res.body, templateFile);
            });
    }

    resetForm() {
        // creating a new network model
        this.networkModel = new Network({});

        this.businessKey = null;
        this.fileIsReady = false;
        this.processing = false;

        this.fileInput.nativeElement.value = '';
        this.step = 0;
    }
}
