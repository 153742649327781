import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SETTINGS_SERVICE, SettingsService, ZITI_DATA_SERVICE, ZitiDataService} from 'ziti-console-lib';
import { HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';

import { isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ZitiApiInterceptor implements HttpInterceptor {
    constructor(
      @Inject(SETTINGS_SERVICE) private settingsService: SettingsService,
      @Inject(HTTP_CLIENT)private httpClient: HttpClient,
      private logger: LoggerService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isEmpty(this.settingsService?.settings?.session)) {
            return next.handle(req);
        } else {
            let request = this.addAuthPolicyId(req);
            request = this.addAuthToken(request);
            return next.handle(request).pipe(
                switchMap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse && this.shouldBeIntercepted(event)) {
                        // Trigger a second request based on the response of the first request
                        const paging = {
                            searchOn: 'name',
                            filter: '',
                            total: 1,
                            page: 1,
                            sort: 'name',
                            order: 'asc'
                        };
                        return this.getZitiData('identities', paging).pipe(
                          switchMap((secondResponse: any) => {
                              event.body.data.identities = secondResponse.meta.pagination.totalCount;
                              return of(event);
                          })
                        );
                    }
                    return of(event);
                })
            );
        }
    }

    shouldBeIntercepted(event) {
        return event.url.indexOf('edge/management/v1/summary') >= 0;
    }

    private addAuthToken(request: HttpRequest<any>) {
        const session = this.settingsService.settings.session;
        return request.clone({ setHeaders: { 'zt-session': session.id } });
    }

    private addAuthPolicyId(request: HttpRequest<any>) {
      const url = request.url;
      const authPolicyId = this.settingsService.settings.nfAdminAuthPolicyId;
      if (this.interceptZitiIdentitiesRequest(request, authPolicyId)) {
        let filter: any = request.params.get('filter')?.toString();
        if (isEmpty(filter)) {
          const urlParams = new URLSearchParams(new URL(url).search);
          filter = urlParams.get('filter')?.toString();
          if (isEmpty(filter)) {
            filter = `authPolicyId != "${authPolicyId}"`;
            request = request.clone({
              params: request.params.set('filter', filter)
            });
          } else {
            filter += ` and authPolicyId != "${authPolicyId}"`;
            filter = filter.replace('name contains "%" and ', '');
            filter = filter.replace('name contains "%"', '');
            request = request.clone({
              url: this.setURLParam(url, 'filter', filter)
            });
          }
        } else {
          filter += `authPolicyId != "${authPolicyId}"`;
          request = request.clone({
            params: request.params.set('filter', filter)
          });
        }
      }
      return request;
    }

    private interceptZitiIdentitiesRequest(request, authPolicyId) {
      const url = request.url;
      const urlWithoutParams = url.split('?')[0];
      const matchString = 'edge/management/v1/identities';
      if (request.method !== 'GET' || isEmpty(authPolicyId) || urlWithoutParams.indexOf(matchString) < 0) {
        return false;
      }
      const urlLength = urlWithoutParams.length;
      const matchStringLength = matchString.length;
      const maxMatchStringIndexPlusLength = urlWithoutParams.indexOf(matchString) + matchStringLength + 1;
      return maxMatchStringIndexPlusLength >= urlLength;
    }

    setURLParam(url: any, param: any, value: any) {
      const urlObj = new URL(url);
      const searchParams = urlObj.searchParams;
      searchParams.set(param, value);
      return urlObj.toString();
    }

    getZitiData(type: string, paging: any) {
      const apiVersions = this.settingsService.apiVersions || {};
      const prefix = apiVersions["edge-management"]?.v1?.path || '/edge/management/v1';
      const url = this.settingsService.settings.selectedEdgeController;
      const serviceUrl = url + prefix + "/" + type;

      return this.httpClient.get(serviceUrl,{}).pipe(
          catchError((err: any) => {
            const error = "Server Not Accessible";
            this.logger.error('Error getting identities for summary');
            throw(err);
          }),
          map((results: any) => {
            return results;
          })
      );
    }
}
