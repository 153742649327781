<div class="select-network-modal-container">
    <div class="title-container">
      <div class="nf-icon"></div>
      <div class="title">Select a Network</div>
    </div>
    <div class="selector-container">
      <lib-item-selector
        [(currentItem)]="selectedNetwork"
        [isOpened]="true"
        [alwaysOpen]="true"
        [items]="networks"
        [showHeader]="false"
        [addNewItemLabel]="'Add a New Network'"
        [manageItemsLabel]="'Manage Networks'"
        [manageItemsLink]="'/networks'"
        (currentItemChange)="setNetwork($event)"
        (addNewItem)="addNetwork($event)"
        (goToManageItems)="manageItemsSelected($event)"
      >
      </lib-item-selector>
    </div>
    <div class="selected-network-container" [ngClass]="{'disabled': !selectedNetwork?.selected}">
      <div class="selected-network-label">
        <div class="selectNetwork row">
          <div class="status" [ngClass]="{'sPROVISIONED': selectedNetwork.selected}">
            <div class="anim5"></div>
          </div>
          {{ selectedNetwork.selected ? selectedNetwork.name : 'no network selected...' }}
        </div>
      </div>
    </div>
    <div class="remember-network-container" [ngClass]="{'disabled': !selectedNetwork?.selected}">
      <div class="cSelect" [ngClass]="{'selected': rememberSelection}" (click)="toggleRemember($event)"></div>
      <div class="remember-network-label">Remember my selection next time</div>
    </div>
    <div class="buttons">
      <button (click)="confirm()" class="save alert" [ngClass]="{'disabled': !selectedNetwork?.selected}">
        Connect
      </button>
    </div>
</div>
