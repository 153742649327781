import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { firstValueFrom, lastValueFrom, ReplaySubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ErrorHistoryService } from './error-history.service';
import { LoggerService } from './logger.service';
import { HTTP_CLIENT } from './token.service';

@Injectable({ providedIn: 'root' })
export class BrandingModeService {
    public brandingAvailable = false;
    public darkModeEnabled = false;
    public tenantLabel;
    public domain;
    public logosAvailable;
    public logoCss;
    private _theme = 'light';
    private _primary = '#0273fb';
    private _secondary = '#08dc5a';
    private data = new ReplaySubject<boolean>();
    public brandingUpdates = this.data.asObservable();
    private s3BucketPrefix = 'nf-console-branding-';

    constructor(
        protected logger: LoggerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(HTTP_CLIENT) public httpClient: HttpClient,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    async fetchBrandCss(tenantLabel?: string): Promise<any> {
        if (!tenantLabel) {
            this.tenantLabel = await firstValueFrom(this.apiService.currentTenant.pipe(catchError((err) => null))).then(
                (tenant: any) => tenant.label,
                (error) => {
                    this.logosAvailable = false;
                }
            );
            // bail if we're not logged in yet
            if (!this.tenantLabel) {
                this.logosAvailable = false;
                return;
            }
        } else this.tenantLabel = tenantLabel;

        this.domain = this.environment.domain;

        this._checkDarkMode();
        if (this.environment.domain !== 'localhost' && this.environment.domain !== 'uuix') {
            const brandUrl = `https://s3.amazonaws.com/${this.s3BucketPrefix}${this.domain}/${this.tenantLabel}/css/${this._theme}.css`;

            // use a service call to get the CSS as text otherwise angular and the browser fire security alarms
            const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

            return lastValueFrom(
                this.httpClient.get(brandUrl, { headers: headers, responseType: 'text' }).pipe(
                    catchError((err) => {
                        this.brandingAvailable = false;
                        this.logosAvailable = false;
                        this.errorHistoryService.addError(err.message);
                        return null;
                    })
                )
            ).then((res) => {
                // strip out CSS brackets so we don't have to do a CORS security bypass
                let rawCss;
                if (typeof res === 'string') {
                    rawCss = res.replace(':root {', '').replace('}', '');
                }
                this.logger.info('Inject branding CSS', rawCss);
                const parts = rawCss.split(';');
                this._primary = parts[0].split(':')[1].trim();
                this._secondary = parts[1].split(':')[1].trim();
                this.logger.info('Branding primary color', this._primary);
                this.logger.info('Branding secondary color', this._secondary);
                this.brandingAvailable = true;

                // set the logo URL
                // get the logos if we have them
                return this.getLogos(rawCss);
            });
        } else return Promise.resolve();
    }

    private getLogos(rawCss): Promise<any> {
        //const html = document.getElementsByTagName('html')[0];
        const logoCssUrl = `https://s3.amazonaws.com/${this.s3BucketPrefix}${this.domain}/${this.tenantLabel}/css/logo.css`;
        const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

        return lastValueFrom(
            this.httpClient.get(logoCssUrl, { headers: headers, responseType: 'text' }).pipe(
                catchError((err) => {
                    //html.style.cssText = rawCss;
                    this.logosAvailable = false;
                    this.sendBrandingUpdate(true);
                    return rawCss;
                })
            )
        ).then((res) => {
            let logoCss;
            if (typeof res === 'string') {
                logoCss = res.replace(':root {', '').replace('}', '');
            }
            this.logger.info('Inject Logos', logoCss);

            // wait until both requests are complete to inject the CSS
            //html.style.cssText = rawCss + logoCss;
            this.logoCss = logoCss;
            this.logosAvailable = true;
            this.sendBrandingUpdate(true);
            return rawCss + logoCss;
        });
    }

    // This is the restore called upon a logout, to persist the color restore, need to do that from the branding component
    public restore() {
        this._primary = '#0273fb';
        this._secondary = '#08dc5a';
        const html = document.getElementsByTagName('html')[0];
        html.style.cssText = '';
    }

    getPrimary() {
        return this._primary;
    }

    getSecondary() {
        return this._secondary;
    }

    private sendBrandingUpdate(data: boolean) {
        this.data.next(data);
    }

    private _checkDarkMode() {
        if (localStorage.getItem('darkMode_' + this.tenantLabel) === 'true') {
            this._theme = 'dark';
            this.darkModeEnabled = true;
            this.logger.info('Dark mode is enabled');
        }
    }
}
