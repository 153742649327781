import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableHeaderDefaultComponent, TableHeaderSelectComponent } from '@netfoundry-ui/feature/data-table';
import { AdvancedPlatformServiceFormComponent } from '@netfoundry-ui/feature/form/advanced-platform-service-form';
import { PlatformServiceFormComponent } from '@netfoundry-ui/feature/form/platform-service-form';
import { PostureCheckFormComponent } from '@netfoundry-ui/feature/form/posture-check-form';
import { ZitiAppwanComponent } from '@netfoundry-ui/feature/form/ziti-appwan';
import { ZitiEdgeRouterComponent } from '@netfoundry-ui/feature/form/ziti-edge-router-form';
import { ZitiEdgeRouterPolicyComponent } from '@netfoundry-ui/feature/form/ziti-edge-router-policy';
import { ZitiEndpointFormComponent } from '@netfoundry-ui/feature/form/ziti-endpoint-form';

import { AppWanV2, PlatformService } from '@netfoundry-ui/shared/model';
import { ValidateService } from '@netfoundry-ui/shared/services';
import isValidPath from 'is-valid-path';
import {
    debounce,
    defer,
    every,
    filter,
    get,
    has,
    head,
    includes,
    isEmpty,
    set,
    some,
    split,
    tail,
    trim,
} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ZitiResourceHelper {
    dialogRef;
    openServiceDebounced = debounce(this.openService.bind(this), 300);
    openEndpointDebounced = debounce(this.openEndpoint.bind(this), 300);
    openAppWANDebounced = debounce(this.openAppWAN.bind(this), 300);
    openEdgeRouterDebounced = debounce(this.openEdgeRouter.bind(this), 300);
    openEdgeRouterPolicyDebounced = debounce(this.openEdgeRouterPolicy.bind(this), 300);
    openPostureCheckDebounced = debounce(this.openPostureCheck.bind(this), 300);
    ZITI_SERVICE_EDGE_ROUTER_POLICY_COLUMN_DEFS = [
        {
            colId: 'name',
            width: 350,
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            cellRendererParams: { resourceType: 'service-edge-router-policy' },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'serviceAttributes',
            field: 'serviceAttributes',
            minWidth: 100,
            tooltipField: 'serviceAttributes',
            headerName: 'Service Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'edgeRouterAttributes',
            field: 'edgeRouterAttributes',
            minWidth: 100,
            tooltipField: 'edgeRouterAttributes',
            headerName: 'Edge Router Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'managed',
            width: 100,
            minWidth: 100,
            cellRenderer: 'cellManagedComponent',
            field: 'managed',
            sortable: false,
            hide: true,
            headerComponent: TableHeaderDefaultComponent,
            //headerComponentParams: managedParams,
            headerName: 'Managed',
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
    ];
    private selectColumnDefinition = {
        colId: 'nf-ag-selected',
        field: 'nf-ag-selected',
        suppressSizeToFit: true,
        lockPosition: true,
        suppressMovable: true,
        resizable: false,
        pinned: 'left',
        width: 60,
        sortable: false,
        headerClass: 'selectHeader',
        cellClass: 'tCol',
        cellRenderer: 'cellSelectComponent',
        cellRendererParams: {
            toggleItem: (item, params) => {
                params.api.nfRowData.forEach((rowItem) => {
                    if (rowItem.itemIndex === item.itemIndex) {
                        item.selected = !item.selected;
                    }
                });
                params.api.refreshCells({ force: true });
                params.api.rowsToggled();
            },
        },
        headerComponent: TableHeaderSelectComponent,
        headerComponentParams: {
            toggleAll: (selected: boolean, params) => {
                params.api.nfRowData.forEach((rowItem) => {
                    rowItem.selected = selected;
                });
                params.api.rowsToggled();
                defer(() => {
                    params.api.redrawRows();
                    params.api.refreshCells({ force: true });
                });
            },
        },
    };
    ZITI_SERVICE_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            lockPosition: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openServiceDebounced.bind(this),
        },
        {
            colId: 'attributes',
            minWidth: 100,
            field: 'attributes',
            tooltipField: 'attributes',
            editable: true,
            lockPosition: true,
            headerName: 'Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openServiceDebounced.bind(this),
        },
        {
            colId: 'clientIngressHost',
            minWidth: 100,
            valueFormatter: this.addressesFormatter.bind(this),
            valueSetter: this.addressesSetter.bind(this),
            editable: true,
            lockPosition: true,
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            headerName: 'Client Host',
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openServiceDebounced.bind(this),
        },
        {
            colId: 'clientIngressPortRange',
            minWidth: 100,
            width: 100,
            valueFormatter: this.portRangeFormatter.bind(this),
            valueSetter: this.portRangeSetter.bind(this),
            editable: true,
            lockPosition: true,
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            headerName: 'Client Port Ranges',
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openServiceDebounced.bind(this),
        },
        {
            colId: 'modelType',
            minWidth: 100,
            width: 250,
            field: 'modelType',
            sortable: false,
            editable: true,
            headerName: 'Service Type',
            headerComponent: TableHeaderDefaultComponent,
            lockPosition: true,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openServiceDebounced.bind(this),
        },
    ];

    ZITI_ENDPOINT_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEndpointDebounced.bind(this),
        },
        {
            colId: 'attributes',
            minWidth: 100,
            field: 'attributes',
            tooltipField: 'attributes',
            editable: true,
            headerName: 'Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEndpointDebounced.bind(this),
        },
    ];

    ZITI_APPWANS_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openAppWANDebounced.bind(this),
        },
        {
            colId: 'mfaEnabled',
            minWidth: 80,
            width: 100,
            valueFormatter: this.mfaEnabled.bind(this),
            field: 'mfaEnabled',
            headerName: 'MFA',
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openAppWANDebounced.bind(this),
        },
        {
            colId: 'serviceAttributes',
            minWidth: 100,
            field: 'serviceAttributes',
            editable: true,
            tooltipField: 'attributes',
            headerName: 'Service Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openAppWANDebounced.bind(this),
        },
        {
            colId: 'endpointAttributes',
            minWidth: 100,
            field: 'endpointAttributes',
            editable: true,
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            headerName: 'Endpoint Attributes',
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            onCellClicked: this.openAppWANDebounced.bind(this),
        },
        {
            colId: 'postureCheckAttributes',
            minWidth: 100,
            field: 'postureCheckAttributes',
            editable: true,
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            headerName: 'Posture Check Attributes',
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            onCellClicked: this.openAppWANDebounced.bind(this),
        },
    ];

    EDGE_ROUTER_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEdgeRouterDebounced.bind(this),
        },
        {
            colId: 'attributes',
            field: 'attributes',
            minWidth: 100,
            tooltipField: 'attributes',
            headerName: 'Attributes',
            headerComponent: TableHeaderDefaultComponent,
            sortable: false,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEdgeRouterDebounced.bind(this),
        },
    ];

    EDGE_ROUTER_POLICY_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEdgeRouterPolicyDebounced.bind(this),
        },
        {
            colId: 'edgeRouterAttributes',
            minWidth: 100,
            field: 'edgeRouterAttributes',
            tooltipField: 'edgeRouterAttributes',
            editable: true,
            headerName: 'Edge Router Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEdgeRouterPolicyDebounced.bind(this),
        },
        {
            colId: 'endpointAttributes',
            minWidth: 100,
            field: 'endpointAttributes',
            tooltipField: 'endpointAttributes',
            editable: true,
            headerName: 'Endpoint Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openEdgeRouterPolicyDebounced.bind(this),
        },
    ];

    POSTURE_CHECK_COLUMN_DEFS = [
        this.selectColumnDefinition,
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            editable: true,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openPostureCheckDebounced.bind(this),
        },
        {
            colId: 'attributes',
            minWidth: 100,
            field: 'attributes',
            tooltipField: 'attributes',
            editable: true,
            headerName: 'Attributes',
            sortable: false,
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
            onCellClicked: this.openPostureCheckDebounced.bind(this),
        },
    ];

    constructor(private validateService: ValidateService, public dialogForm: MatDialog) {}

    portRangeSetter(params) {
        const modelType = get(params, 'data.modelType');
        if (modelType === 'AdvancedTunnelerToEndpoint') {
            const newVal = get(params, 'newValue', '');
            let vals = newVal.split(',');
            vals = vals.map((portRange) => {
                const newVal = split(portRange, '-');
                const low = head(newVal);
                const high = newVal.length === 1 ? low : tail(newVal);
                return { low, high };
            });
            set(params, `data.model.clientIngress.ports`, vals);
        } else {
            set(params, `data.model.clientIngress.port`, params.newValue);
        }
        return true;
    }

    portRangeFormatter(row) {
        const service = row.data as PlatformService;
        if (has(service, 'model.clientIngress.port')) {
            return service.model.clientIngress.port;
        }

        const ports = get(service, 'model.clientIngress.ports', []);
        return ports.map((portRange) => portRange.low + '-' + portRange.high).join(', ');
    }

    addressesSetter(params) {
        const modelType = get(params, 'data.modelType');
        if (modelType === 'AdvancedTunnelerToEndpoint') {
            const newVal = get(params, 'newValue', '');
            let val = newVal.split(',');
            val = val.map((addr) => trim(addr));
            set(params, `data.model.clientIngress.addresses`, val);
        } else {
            set(params, `data.model.clientIngress.host`, params.newValue);
        }
        return true;
    }

    addressesFormatter(row) {
        const service = row.data as PlatformService;
        const clientIngress = get(service, 'model.clientIngress', { addresses: [] });
        const modelType = get(row, 'data.modelType');
        if (modelType === 'AdvancedTunnelerToEndpoint') {
            return clientIngress.addresses.join(', ');
        } else {
            return clientIngress.host;
        }
    }

    mfaEnabled(row) {
        const appWan = row.data as AppWanV2;
        const pcAttrs = get(appWan, 'postureCheckAttributes', []);
        if (pcAttrs.includes('@zitimfa')) {
            return 'Enabled';
        } else {
            return '--';
        }
    }

    getTableColumnDefinitions(resourceType) {
        let columnDefs;
        switch (resourceType) {
            case 'ziti-service':
                columnDefs = this.ZITI_SERVICE_COLUMN_DEFS;
                break;
            case 'ziti-endpoint':
                columnDefs = this.ZITI_ENDPOINT_COLUMN_DEFS;
                break;
            case 'ziti-app-wan':
                columnDefs = this.ZITI_APPWANS_COLUMN_DEFS;
                break;
            case 'edge-router':
                columnDefs = this.EDGE_ROUTER_COLUMN_DEFS;
                break;
            case 'edge-router-policy':
                columnDefs = this.EDGE_ROUTER_POLICY_COLUMN_DEFS;
                break;
            case 'posture-check':
                columnDefs = this.POSTURE_CHECK_COLUMN_DEFS;
                break;
        }
        return columnDefs;
    }

    openAppWAN(params) {
        this.openDialog(params, 'ziti-app-wan');
    }

    openService(params) {
        this.openDialog(params, 'ziti-service');
    }

    openEndpoint(params) {
        this.openDialog(params, 'ziti-endpoint');
    }

    openEdgeRouter(params) {
        this.openDialog(params, 'edge-router');
    }

    openEdgeRouterPolicy(params) {
        this.openDialog(params, 'edge-router-policy');
    }

    openPostureCheck(params) {
        this.openDialog(params, 'posture-check');
    }

    openDialog(params, resourceType) {
        let dialogComponent;
        switch (resourceType) {
            case 'ziti-service':
                if (get(params, 'data.modelType') === 'AdvancedTunnelerToEndpoint') {
                    dialogComponent = AdvancedPlatformServiceFormComponent;
                } else {
                    dialogComponent = PlatformServiceFormComponent;
                }
                break;
            case 'ziti-endpoint':
                dialogComponent = ZitiEndpointFormComponent;
                break;
            case 'ziti-app-wan':
                dialogComponent = ZitiAppwanComponent;
                break;
            case 'edge-router':
                dialogComponent = ZitiEdgeRouterComponent;
                break;
            case 'edge-router-policy':
                dialogComponent = ZitiEdgeRouterPolicyComponent;
                break;
            case 'posture-check':
                dialogComponent = PostureCheckFormComponent;
                break;
        }
        const editingCells = params.api.getEditingCells();
        if (!editingCells || editingCells.length > 0) {
            return;
        }
        this.dialogRef = this.dialogForm.open(dialogComponent, {
            data: {
                model: params.data,
                bulkEdit: true,
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogRef.afterClosed().subscribe(() => {
            params.api.validateTable(params);
            params.api.refreshCells();
        });
    }

    isValidHost(host) {
        return this.validateService.isValidHostName(host) || this.validateService.isValidIP(host);
    }

    validateNetwork(network) {
        const servicesInvalid = some(network.services, (service) => !this.validatePlatformService(service).isValid);
        const endpointsInvalid = some(network.endpoints, (endpoint) => !this.validateEndpoint(endpoint).isValid);
        const appWANsInvalid = some(network.appWans, (appWAN) => !this.validateAppWan(appWAN).isValid);
        const edgeRoutersInvalid = some(network.edgeRouters, (er) => !this.validateEdgeRouter(er).isValid);
        const edgeRouterPoliciesInvalid = some(
            network.edgeRouterPolicies,
            (erp) => !this.validateEdgeRouterPolicy(erp).isValid
        );
        const postureChecksInvalid = some(network.postureChecks, (pc) => !this.validatePostureCheck(pc).isValid);

        return {
            servicesInvalid,
            endpointsInvalid,
            appWANsInvalid,
            edgeRoutersInvalid,
            edgeRouterPoliciesInvalid,
            postureChecksInvalid,
        };
    }

    validateResource(item, resourceType): any {
        let validationResult;
        switch (resourceType) {
            case 'ziti-service':
                validationResult = this.validatePlatformService(item);
                break;
            case 'ziti-endpoint':
                validationResult = this.validateEndpoint(item);
                break;
            case 'ziti-app-wan':
                validationResult = this.validateAppWan(item);
                break;
            case 'edge-router':
                validationResult = this.validateEdgeRouter(item);
                break;
            case 'edge-router-policy':
                validationResult = this.validateEdgeRouterPolicy(item);
                break;
            case 'posture-check':
                validationResult = this.validatePostureCheck(item);
                break;
        }
        return validationResult;
    }

    validateAppWan(model) {
        const errors = {};
        let isValid = true;

        if (!model.name || model.name.length < 5) {
            errors['name'] = true;
            errors['nameLength'] = true;
            isValid = false;
        }

        if (!this.validateService.isValidZitiName(model.name)) {
            errors['name'] = true;
            isValid = false;
        }

        const saResult = this._validateAttributes(model.serviceAttributes, errors, isValid, 'serviceAttributes');
        isValid = saResult.isValid;

        const epResult = this._validateAttributes(model.endpointAttributes, errors, isValid, 'endpointAttributes');
        isValid = epResult.isValid;

        const pcResult = this._validateAttributes(
            model.postureCheckAttributes,
            errors,
            isValid,
            'postureCheckAttributes'
        );
        isValid = pcResult.isValid;

        model.serviceAttributes = saResult.attributes;
        model.endpointAttributes = epResult.attributes;
        model.postureCheckAttributes = pcResult.attributes;
        return { isValid, errors };
    }

    validateEdgeRouter(model) {
        let isValid = true;
        const errors: any = {};

        if (!this.validateService.isValidZitiName(model.name)) {
            errors.name = true;
            isValid = false;
        }
        if (!this.validateService.hasValue(model.name) || model.name.length < 5 || model.name.length > 65) {
            errors.name = true;
            isValid = false;
        }

        const result = this._validateAttributes(model.attributes, errors, isValid, 'attributes');
        isValid = result.isValid;
        model.attributes = result.attributes;

        return { isValid, errors };
    }

    validateEdgeRouterPolicy(model) {
        const errors = {};
        let isValid = true;

        if (!model.name || model.name.length < 5) {
            errors['name'] = true;
            errors['nameLength'] = true;
            isValid = false;
        }

        if (!this.validateService.isValidZitiName(model.name)) {
            errors['name'] = true;
            isValid = false;
        }

        const epResult = this._validateAttributes(model.endpointAttributes, errors, isValid, 'endpointAttributes');
        isValid = epResult.isValid;
        model.attributes = epResult.attributes;

        const erResult = this._validateAttributes(model.edgeRouterAttributes, errors, isValid, 'edgeRouterAttributes');
        isValid = erResult.isValid;
        model.edgeRouterAttributes = erResult.attributes;

        return { isValid, errors };
    }

    validateEndpoint(model) {
        const errors = {};
        let isValid = true;
        if (!model.name || model.name.length < 5) {
            errors['name'] = true;
            errors['nameLength'] = true;
            isValid = false;
        }

        const result = this._validateAttributes(model.attributes, errors, isValid, 'attributes');
        isValid = result.isValid;
        model.attributes = result.attributes;

        return { isValid, errors };
    }

    validatePlatformService(model): any {
        let isValid = true;
        const errors = {
            egressRouterId: [],
        };

        if (model.name && model.name.length < 5) {
            errors['name'] = 'Service name must be at least 5 characters';
            errors['nameLength'] = true;
            isValid = false;
        }

        if (isEmpty(model.name)) {
            errors['name'] = 'Service name can not be empty';
            isValid = false;
        }

        const result = this._validateAttributes(model.attributes, errors, isValid, 'attributes');
        isValid = result.isValid;
        model.attributes = result.attributes;

        if (includes(['TunnelerToSdk', 'TunnelerToEndpoint', 'TunnelerToEdgeRouter'], model.modelType)) {
            if (isEmpty(get(model, 'model.clientIngress.host'))) {
                errors['model.clientIngress.host'] = 'No client host defined';
                isValid = false;
            } else {
                if (!this.isValidHost(model.model.clientIngress.host)) {
                    errors['model.clientIngress.host'] = 'Invalid client host';
                    isValid = false;
                }
            }
            const clientPort = get(model, 'model.clientIngress.port', '').toString();
            if (isEmpty(clientPort)) {
                errors['model.clientIngress.port'] = 'Invalid client host';
                isValid = false;
            } else {
                if (!this.validateService.isValidPort(clientPort)) {
                    errors['model.clientIngress.port'] = 'Invalid client port';
                    isValid = false;
                }
            }
        }

        if (
            !includes(
                ['TunnelerToSdk', 'TunnelerToEndpoint', 'TunnelerToEdgeRouter', 'AdvancedTunnelerToEndpoint'],
                model.modelType
            )
        ) {
            errors['modelType'] = 'Invalid model type';
            isValid = false;
        }

        if (model.modelType === 'TunnelerToSdk') {
            if (isEmpty(model.model.bindEndpointAttributes)) {
                errors['model.serverEgress.endpoints'] = 'No endpoints defined for this SKD hosted service';
                isValid = false;
            }
        }
        if (model.modelType === 'TunnelerToEndpoint') {
            set(model, 'model.serverEgress', get(model, 'model.serverEgress', {}));
            model.model.serverEgress.host = trim(get(model, 'model.serverEgress.host', ''));
            model.model.serverEgress.port = trim(get(model, 'model.serverEgress.port', ''));
            if (
                model.model.serverEgress.host &&
                !this.isValidHost(model.model.serverEgress.host) &&
                !this.isValidHost(model.model.serverEgress.host)
            ) {
                errors['model.serverEgress.host'] = 'Invalid host defined for endpoint hosted service';
                isValid = false;
            }

            if (isEmpty(model.model.bindEndpointAttributes)) {
                errors['model.bindEndpointAttributes'] = 'No endpoints defined for endpoint hosted service';
                isValid = false;
            }

            if (!model.model.serverEgress.host && model.model.serverEgress.port) {
                errors['model.serverEgress.host'] = 'Invalid host for endpoint hosted service';
                isValid = false;
            }

            if (model.model.serverEgress.port && !this.validateService.isValidPort(model.model.serverEgress.port)) {
                errors['model.serverEgress.port'] = 'Invalid port for endpoint hosted service';
                isValid = false;
            }

            if (!model.model.serverEgress.protocol) {
                errors['model.serverEgress.protocol'] = 'Invalid protocol for endpoint hosted service';
                isValid = false;
            }
        }

        if (model.modelType === 'TunnelerToEdgeRouter') {
            set(model, 'model.edgeRouterHosts', get(model, 'model.edgeRouterHosts', []));
            for (let i = 0; i < model.model.edgeRouterHosts.length; i++) {
                const edgeRouterHost = model.model.edgeRouterHosts[i];
                set(edgeRouterHost, 'serverEgress', get(edgeRouterHost, 'serverEgress', {}));
                edgeRouterHost.serverEgress.host = trim(get(edgeRouterHost, 'serverEgress.host', ''));
                edgeRouterHost.serverEgress.port = trim(get(edgeRouterHost, 'serverEgress.port', ''));
                if (edgeRouterHost.serverEgress.host && !this.isValidHost(edgeRouterHost.serverEgress.host)) {
                    errors['model.serverEgress.host'] = 'Invalid host for Edge Router hosted service';
                    isValid = false;
                }

                if (!edgeRouterHost.serverEgress.host && !edgeRouterHost.serverEgress.port) {
                    errors['model.serverEgress.host'] = 'Invalid host for Edge Router hosted service';
                    isValid = false;
                }

                if (
                    (edgeRouterHost.serverEgress.port &&
                        !this.validateService.isValidPort(edgeRouterHost.serverEgress.port)) ||
                    (!edgeRouterHost.serverEgress.port && edgeRouterHost.serverEgress.host)
                ) {
                    errors['model.serverEgress.port'] = 'Invalid port for Edge Router hosted service';
                    isValid = false;
                }

                if (
                    (edgeRouterHost.serverEgress.host || edgeRouterHost.serverEgress.port) &&
                    !edgeRouterHost.serverEgress.protocol
                ) {
                    errors['model.serverEgress.protocol'] = 'Invalid protocol for Edge Router hosted service';
                    isValid = false;
                }

                for (let j = 0; j < model.model.edgeRouterHosts.length; j++) {
                    const otherEdgeRouterHost = model.model.edgeRouterHosts[j];
                    if (i === j) {
                        continue;
                    }
                    if (edgeRouterHost.edgeRouterId) {
                        if (edgeRouterHost.edgeRouterId === otherEdgeRouterHost.edgeRouterId) {
                            errors['model.serverEgress.edgeRouterId'] =
                                'Duplicate edge router entries for this service';
                            isValid = false;
                        }
                    } else if (edgeRouterHost.edgeRouterName === otherEdgeRouterHost.edgeRouterName) {
                        errors['model.serverEgress.edgeRouterName'] = 'Duplicate edge router entries for this service';
                        isValid = false;
                    }
                }
            }
        }

        if (model.modelType === 'AdvancedTunnelerToEndpoint') {
            const ports = get(model, 'model.clientIngress.ports', []);
            if (ports.length <= 0) {
                errors['clientPort'] = true;
                isValid = false;
            } else {
                ports.forEach((portDef) => {
                    if (!this.validateService.isValidPort(portDef.low)) {
                        errors['clientPort'] = true;
                        isValid = false;
                    }
                    if (!this.validateService.isValidPort(portDef.high)) {
                        errors['clientPort'] = true;
                        isValid = false;
                    }
                });
            }

            const protocols = get(model, 'model.clientIngress.protocols', []);
            if (protocols.length <= 0) {
                errors['clientProtocol'] = true;
                isValid = false;
            }

            const addresses = get(model, 'model.clientIngress.addresses', []);
            if (addresses.length <= 0) {
                errors['clientIngress'] = true;
                isValid = false;
            } else {
                addresses.forEach((addr) => {
                    const address = addr.split('/')[0];
                    if (!this.validateService.isValidIP(address) && !this.validateService.isValidHostName(address)) {
                        errors['clientIngress'] = true;
                        isValid = false;
                    }
                });
            }

            if (!get(model, 'model.serverEgress.forwardProtocol', [])) {
                const protocol = get(model, 'model.serverEgress.protocol');
                if (isEmpty(protocol)) {
                    errors['serverProtocol'] = true;
                    isValid = false;
                }
            }
            if (!get(model, 'model.serverEgress.forwardHost', [])) {
                const host = get(model, 'model.serverEgress.host');
                if (!this.validateService.isValidIP(host) && !this.validateService.isValidHostName(host)) {
                    errors['serverHostName'] = true;
                    isValid = false;
                }
            }
            if (!get(model, 'model.serverEgress.forwardPort', [])) {
                const port = get(model, 'model.serverEgress.port');
                if (!this.validateService.isValidPort(port)) {
                    errors['serverPort'] = true;
                    isValid = false;
                }
            }
        }

        return { isValid, errors };
    }

    validatePostureCheck(model) {
        const errors: any = {};
        let isValid = true;

        if (!this.validateService.hasValue(model.name) || model.name.length < 5 || model.name.length > 65) {
            errors.name = true;
            isValid = false;
        } else if (!this.validateService.isValidZitiName(model.name)) {
            errors.name = true;
            isValid = false;
        }

        const result = this._validateAttributes(model.attributes, errors, isValid, 'attributes');
        isValid = result.isValid;
        model.attributes = result.attributes;

        if (!this.validateService.hasValue(model.type)) {
            errors.type = true;
            isValid = false;
        } else {
            if (model.type === 'MFA') {
                isValid = true;
            } else if (model.type === 'PROCESS') {
                const windowsPathRegex = /^[a-zA-Z]:\\[\\\S|*\S]?.*$/;
                // TODO validate the path is in correct form
                if (
                    !this.validateService.hasValue(get(model, 'data.process.path')) ||
                    (!isValidPath(model.data.process.path) && !model.data.process.path.match(windowsPathRegex))
                ) {
                    errors.path = true;
                    isValid = false;
                }
                if (this.validateService.hasValue(model.data.hash) && !this.validateService.isHex(model.data.hash)) {
                    errors.hash = true;
                    isValid = false;
                }
            } else if (model.type === 'OS') {
                isValid = every(model.data.operatingSystems, (os) => this.validateOSVersionPostureCheck(os));
                if (!isValid) {
                    errors.os = true;
                }
            } else if (model.type === 'DOMAIN') {
                isValid = this.validateDomainPostureCheck(model.data.domains);
                if (!isValid) {
                    errors.domain = true;
                }
            } else if (model.type === 'MAC') {
                isValid = this.validateMacAddressPostureCheck(model.data.macAddresses);
                if (!isValid) {
                    errors.mac = true;
                }
            } else {
                errors.type = true;
                isValid = false;
            }
        }
        return { isValid, errors };
    }

    validateOSVersionPostureCheck(operatingSystem) {
        const windowsVersions = operatingSystem.versions[0].split(' ');
        const windowsMinVersion = windowsVersions[0].replace('>', '').replace('=', '');
        const minVerValid =
            this.validateService.isValidVersion(windowsMinVersion) &&
            this.validateService.isValidSemver(windowsMinVersion);
        let maxVerValid = true;
        if (windowsVersions.length === 2) {
            const windowsMaxVersion = windowsVersions[1].replace('<', '').replace('=', '');
            maxVerValid =
                this.validateService.isValidVersion(windowsMinVersion) &&
                this.validateService.isValidSemver(windowsMinVersion) &&
                this.validateService.isVersionLessThan(windowsMinVersion, windowsMaxVersion);
        }
        const typeValid = this.validateService.hasValue(operatingSystem.type);
        return minVerValid && maxVerValid && typeValid;
    }

    validateDomainPostureCheck(domains) {
        return every(
            domains,
            (domain) => this.validateService.hasValue(domain) && this.validateService.isValidWindowsDomain(domain)
        );
    }

    validateMacAddressPostureCheck(macAddresses) {
        return every(
            macAddresses,
            (macAddress) =>
                this.validateService.hasValue(macAddress) && this.validateService.isValidMacAddress(macAddress)
        );
    }

    _validateAttributes(attributes, errors, isValid, key) {
        if (!attributes) {
            return { attributes, errors, isValid };
        }
        attributes = filter(attributes, (attr) => !isEmpty(attr));
        attributes = attributes.map((attr) => {
            let firstChar = attr.charAt(0);
            let attribute = '';
            if (firstChar === '#' || firstChar === '@') {
                attribute = attr.substring(1);
            } else {
                attribute = attr;
                firstChar = '#';
            }
            if (
                (firstChar === '#' && !this.validateService.isValidZitiAttribute(attribute)) ||
                (firstChar === '@' && !this.validateService.isValidZitiName(attribute))
            ) {
                errors[key] = 'Invalid attribute name';
                isValid = false;
            } else {
                attr = firstChar + attribute;
            }
            return attr;
        });
        return { attributes, errors, isValid };
    }

    public getOptions(currentNetwork, embedAll = false) {
        if (!currentNetwork) {
            return {};
        }
        const params = [{ key: 'networkId', value: currentNetwork.id }];
        if (embedAll) {
            params.push({ key: 'embed', value: 'all' });
        }
        return { params };
    }

    public getOptionsNew(currentNetwork, embedAll = false) {
        if (!currentNetwork) {
            return {};
        }
        const params = { networkId: currentNetwork.id };
        if (embedAll) {
            params['embed'] = 'all';
        }
        return { params };
    }
}
