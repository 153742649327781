import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import _ from 'lodash';
import { createAjvValidator, JSONEditor, Mode } from 'vanilla-jsoneditor';

@Component({
    selector: 'app-json-view',
    templateUrl: './json-view.component.html',
    styleUrls: ['./json-view.component.scss'],
})
export class JsonViewComponent implements AfterViewInit, OnChanges {
    @Input() data: any;
    @Input() schema: any;
    @Input() readOnly: boolean;
    @Input() jsonInvalid: boolean;
    @Output() dataChange: EventEmitter<any> = new EventEmitter();
    @Output() jsonChange: EventEmitter<any> = new EventEmitter();
    @Output() jsonInvalidChange: EventEmitter<any> = new EventEmitter();
    onChangeDebounced;
    schemaRefs: any;
    content: any;
    @ViewChild('editor', { static: false }) editorDiv!: ElementRef;
    validator: any;
    private editor: JSONEditor;
    private schemaDefinitions: any;

    constructor() {
        this.onChangeDebounced = _.debounce(this.onChange.bind(this), 400);
    }

    ngAfterViewInit() {
        if (this.schema) {
            this.validator = createAjvValidator({
                schema: this.schema,
                schemaDefinitions: this.schemaDefinitions,
                ajvOptions: {},
            });
        }
        this.content = {
            json: this.data,
        };
        this.editor = new JSONEditor({
            target: this.editorDiv.nativeElement,
            props: {
                mode: Mode.text,
                validator: this.validator,
                content: this.content,
                onChange: this.onChangeDebounced,
            },
        });
    }
    onChange(updatedContent, previousContent, { contentErrors, patchResult }) {
        this.content = updatedContent;
        if (this.content?.text) {
            this.dataChange.emit(this.content.text);
        }
        if (this.content?.json) {
            this.jsonChange.emit(this.content.json);
        }
    }

    public getData() {
        return this.editor.get();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.schema) {
            this.schema = changes.schema.currentValue;

            if (this.schema) {
                if (this.validator) {
                    this.validator.schema = this.schema;
                } else {
                    this.validator = createAjvValidator({
                        schema: changes.schema.currentValue,
                        schemaDefinitions: this.schemaDefinitions,
                        ajvOptions: {},
                    });
                }
            }
        }
    }

    public validate(newData: any = undefined) {
        return this.editor.validate();
    }
}
