import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { NetworkGroupService, NetworkService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-network-group-form',
    templateUrl: './network-group-form.component.html',
    styleUrls: ['./network-group-form.component.scss'],
})
export class NetworkGroupFormComponent {
    editing = false;
    processing = false;
    model: NetworkGroup = new NetworkGroup({});

    isLoading = false;

    newNetworkModel: Network = new Network({});

    subscription: Subscription = new Subscription();

    canCreateNetworks = false;

    errorName = false;
    errorNetworkName = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<NetworkGroupFormComponent>,
        private validateService: ValidateService,
        public authorizationService: AuthorizationService,
        private networkGroupService: NetworkGroupService,
        private networkService: NetworkService
    ) {
        this.canCreateNetworks = this.authorizationService.canCreateNetworks('\\*');

        // defaulting to display the new api account form
        this.editing = false;

        // if data.model is provided, the api account identity is being edited
        if (this.data.model !== undefined) {
            // setting editing to true
            this.editing = true;

            // storing the model provided
            this.model = this.data.model;
            // if this.data.reset is provided, display the new information
        }
    }

    // fucntion for closing the dilaog
    cancel() {
        this.dialogRef.close();
    }

    // fucntion for creating a new api account
    async save() {
        // doing some basic validation on the form
        if (this.validate()) {
            this.processing = true;
            this.isLoading = true;
            // Generate the token
            const successfulSave = await this.networkGroupService
                .save(this.model)
                .toPromise()
                .then(
                    (result) => {
                        if (this.model.id === '') {
                            this.model = new NetworkGroup(result);
                        }

                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Network Group Created',
                                'Network Group information has been saved'
                            )
                        );
                        return true;
                    },
                    (error) => {
                        this.processing = false;
                        this.isLoading = false;
                        return false;
                    }
                );

            if (!this.editing && successfulSave) {
                if (this.validateService.hasValue(this.newNetworkModel.name)) {
                    this.subscription.add(
                        this.networkService.save(this.newNetworkModel, this.model.id).subscribe(
                            (network) => {
                                this.newNetworkModel = new Network(network);
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Network Created',
                                        'Network information has been saved'
                                    )
                                );
                                this.dialogRef.close({
                                    networkGroup: this.model,
                                    network: this.newNetworkModel,
                                });
                            },
                            (error) => {
                                this.dialogRef.close({ networkGroup: this.model });
                            }
                        )
                    );
                } else {
                    this.cancel();
                }
            } else if (this.editing) {
                this.dialogRef.close({ networkGroup: this.model });
            }
        }
    }

    // function for doing basic validation on the form
    validate() {
        // setting isValid to true initially
        let isValid = true;

        // setting errorName and errorContactEmail to fale
        this.errorName = false;
        this.errorNetworkName = false;

        // checking that a name was provided
        if (!this.validateService.isValidName(this.model.name)) {
            this.errorName = true;
            isValid = false;
        }

        if (!this.editing) {
            if (
                this.validateService.hasValue(this.newNetworkModel.name) &&
                !this.validateService.isValidNetworkName(this.newNetworkModel.name)
            ) {
                this.errorNetworkName = true;
                isValid = false;
            }
        }
        // returning isValid
        return isValid;
    }
}
