import { Injectable } from '@angular/core';
import * as isDomainName from 'is-domain-name';
import * as netmask from 'netmask';
import * as postcodeValidator from 'postcode-validator';
import * as semver from 'semver';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const cidrRegex = require('cidr-regex');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ipRegex = require('ip-regex');
const nameWithSpaceRegEx = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

// eslint-disable-next-line
const genericZipCodeRegex = /^[.a-zA-Z0-9 \-\ ]+$/;

const alphaRegex = /[a-zA-Z]/;
const addressRegEx = /^[0-9a-zA-Z]+(([',. -][0-9 a-zA-Z a-zA-Z])?[a-zA-Z0-9_]*)*[^*|":~<>[\]{}`\\()';%!@&$]+$/;
const nameRegEx = RegExp('^[A-Za-z0-9-\\s\\[\\].]{5,65}$');
const networkNameRegEx = RegExp('^[A-Za-z0-9-_\\s]{5,65}$');
const haNameRegex = RegExp('^[A-Za-z0-9]([A-Za-z0-9 \\.\\-]{0,57}[A-Za-z0-9])?$');
const zitiNameRegEx = RegExp('^[A-Za-z0-9-_()\\s.]{5,255}$');
const nospecial = /[^*|":~<>[\]{}`\\()';%!@&$]+$/;
/* eslint-disable no-useless-escape */
// regular expression found online based on RFC 5322
// eslint-disable-next-line no-useless-escape
const emailRegex =
    /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const hexRegex = /^[0-9A-Fa-f]*$/;

const macAddressRegex =
    /^((([a-fA-F0-9][a-fA-F0-9]+[-]){5}|([a-fA-F0-9][a-fA-F0-9]+[:]){5})([a-fA-F0-9][a-fA-F0-9])$)|(^([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]+[.]){2}([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]))$/;

/**
 * The below regex defines a list of emails with each email separated by a semicolon. The list can contain
 *    white spaces at the beginning and the end of the list, it can end with a semicolon or without one
 *    and contains one or more email address with no other separators
 *
 * The following breaks down the regex in more detail
 *
 * ^\s{0}
 * string that starts with 0 or more white spaces
 *
 * (([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2}))
 * followed by an email (same regular expression based on RFC 5322 found online)
 *
 * \s{0}
 * followed by 0 or more white spaces
 *
 *
 * (;\s{0}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2}))\s{0}){0}
 * grouping of a semicolon followed by 0 or more white space followed by an email (as defined above) followed by 0 or more white space
 *    with this group occuring 0 or more times
 *
 * ;{0,1}\s{0}$
 * 0 or one semi colons followed by 0 or more white spaces and the end of the string
 * */

const emailListRegex =
    /^\s{0,}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s{0,}(;\s{0,}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s{0,}){0,};{0,}\s{0,}$/;
const invalidString = 'Invalid';
const requiredString = 'Required';

const alphaNumericRegex = /^[a-zA-Z0-9]*$/;

const zitiAlphaNumericRegex = /^[a-zA-Z0-9 _-]{3,65}$/;

const versionRegex = /^[0-9]+\.[0-9]+\.[0-9]+$/;

const numeric = /^[0-9]*$/;

const ipRegex_ = ipRegex;
const cidrRegex_ = cidrRegex;
const isDomainName_ = isDomainName;

const domainNameRegex =
    /^(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?\.){0,126}(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))\..+$/i;

const domainNameWithWildRegex =
    /^((\*\.)?|(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?\.){0,126})(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))(\..+$)?/i;

const hostNameRegex =
    /^(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?\.){0,126}(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))\.?$/i;

const hostNameWithWildRegex =
    /^((\*\.)?|(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?\.){0,126})(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))(\.?$)?/i;

const countryCodes = [
    'UK',
    'JE',
    'GG',
    'IM',
    'US',
    'CA',
    'IE',
    'DE',
    'JP',
    'FR',
    'AU',
    'IT',
    'CH',
    'AT',
    'ES',
    'NL',
    'BE',
    'DK',
    'SE',
    'NO',
    'BR',
    'PT',
    'FI',
    'AX',
    'KR',
    'CN',
    'TW',
    'SG',
    'DZ',
    'AD',
    'AR',
    'AM',
    'AZ',
    'BH',
    'BD',
    'BB',
    'BY',
    'BM',
    'BA',
    'IO',
    'BN',
    'BG',
    'KH',
    'CV',
    'CL',
    'CR',
    'HR',
    'CY',
    'CZ',
    'DO',
    'EC',
    'EG',
    'EE',
    'FO',
    'GE',
    'GR',
    'GL',
    'GT',
    'HT',
    'HN',
    'HU',
    'IS',
    'IN',
    'ID',
    'IL',
    'JO',
    'KZ',
    'KE',
    'KW',
    'LA',
    'LV',
    'LB',
    'LI',
    'LT',
    'LU',
    'MK',
    'MY',
    'MV',
    'MT',
    'MU',
    'MX',
    'MD',
    'MC',
    'MA',
    'NP',
    'NZ',
    'NI',
    'NG',
    'OM',
    'PK',
    'PY',
    'PH',
    'PL',
    'PR',
    'RO',
    'RU',
    'SM',
    'SA',
    'SN',
    'SK',
    'SI',
    'ZA',
    'LK',
    'TJ',
    'TH',
    'TN',
    'TR',
    'TM',
    'UA',
    'UY',
    'UZ',
    'VA',
    'VE',
    'ZM',
    'AS',
    'CC',
    'CK',
    'RS',
    'ME',
    'CS',
    'YU',
    'CX',
    'ET',
    'FK',
    'NF',
    'FM',
    'GF',
    'GN',
    'GP',
    'GS',
    'GU',
    'GW',
    'HM',
    'IQ',
    'KG',
    'LR',
    'LS',
    'MG',
    'MH',
    'MN',
    'MP',
    'MQ',
    'NC',
    'NE',
    'VI',
    'PF',
    'PG',
    'PM',
    'PN',
    'PW',
    'RE',
    'SH',
    'SJ',
    'SO',
    'SZ',
    'TC',
    'WF',
    'XK',
    'YT',
];

@Injectable({ providedIn: 'root' })
export class ValidateService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    isValidIP(val: string) {
        return ipRegex_({ exact: true }).test(val);
    }

    isValidCidrIP(val: string) {
        try {
            const testNetMask = new netmask.Netmask(val);
            const isValidCidr = cidrRegex_({ exact: true }).test(val);

            // cidrRegex will check if the cidr block is between 0 and 32, however per conversations Shawn, we only want to allow 8 through 30
            // additionally, checking if the base of the network address matches the address provided
            if (isValidCidr && testNetMask.base === val.split('/')[0]) {
                const bitMask = testNetMask.bitmask;
                return bitMask > 0 && bitMask < 33;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    isValidPort(val) {
        if (val == null || isNaN(val)) {
            return false;
        }
        return val >= 1 && val <= 65535;
    }

    hasValue(value) {
        if (value === null || value === undefined || value.toString().trim().length === 0) {
            return false;
        }

        return true;
    }

    isValidName(value) {
        const nameHasValue = this.hasValue(value);
        return nameHasValue ? nameRegEx.test(value) : false;
    }

    isValidZitiName(value) {
        const nameHasValue = this.hasValue(value);
        return nameHasValue ? zitiNameRegEx.test(value) : false;
    }

    isValidNetworkName(value) {
        const nameHasValue = this.hasValue(value);
        return nameHasValue ? networkNameRegEx.test(value) : false;
    }

    isNumeric(value) {
        const numberHasValue = this.hasValue(value);
        return numberHasValue && numeric.test(value);
    }

    isValidHaName(value) {
        const nameHasValue = this.hasValue(value);
        return nameHasValue ? haNameRegex.test(value) : false;
    }

    isValidEmail(value) {
        const emailHasValue = this.hasValue(value);
        return emailHasValue ? emailRegex.test(value) : false;
    }

    isValidVersion(value) {
        const versionHasValue = this.hasValue(value);
        return versionHasValue ? versionRegex.test(value) : false;
    }

    isValidSemver(value) {
        const semverHasValue = this.hasValue(value);
        return semverHasValue && semver.valid(value) !== null;
    }

    isVersionLessThan(min, max) {
        const minHasValue = this.hasValue(min);
        const maxHasValue = this.hasValue(max);
        return maxHasValue && minHasValue && semver.lte(min, max);
    }

    isValidPortOrPortRange(val) {
        if (!val) {
            return false;
        }
        const portRange = val.split('-');

        if (portRange.length > 1) {
            // first number must be lower
            if (Number(portRange[0]) >= Number(portRange[1])) {
                return false;
            }

            if (!this.isValidPort(portRange[0]) || !this.isValidPort(portRange[1])) {
                return false;
            }

            if (portRange.length > 2) {
                return false;
            }

            return true;
        } else {
            return this.isValidPort(val);
        }
    }

    isValidHostName(val, allowWildCards = false) {
        const hostnameHasValue = this.hasValue(val);
        if (allowWildCards) {
            return hostnameHasValue ? domainNameWithWildRegex.test(val) : false;
        }
        return hostnameHasValue ? domainNameRegex.test(val) : false;
    }

    isValidHostHostname(val, allowWildCards = false) {
        const hostHostNameHasValue = this.hasValue(val);
        if (allowWildCards) {
            return hostHostNameHasValue ? hostNameWithWildRegex.test(val) : false;
        }
        return hostHostNameHasValue ? hostNameRegex.test(val) : false;
    }

    isGenericZipCode(val) {
        if (this.hasValue(val)) {
            return genericZipCodeRegex.test(val);
        } else {
            return false;
        }
    }

    isTwoCharString(val) {
        const cst = /^[A-Za-z]{2}/;
        if (this.hasValue(val) && val.length === 2) {
            return cst.test(val);
        } else {
            return false;
        }
    }

    isValidNameString(val) {
        if (this.hasValue(val)) {
            // return alphaNumericRegex.test(val);
            return nameWithSpaceRegEx.test(val);
        } else {
            return false;
        }
    }

    isValidAlphaString(val) {
        if (this.hasValue(val)) {
            return alphaRegex.test(val);
        } else {
            return false;
        }
    }

    isValidGenericAddressString(val) {
        if (this.hasValue(val)) {
            return addressRegEx.test(val);
        } else {
            return false;
        }
    }

    noSpecialCharacters(val) {
        if (this.hasValue(val)) {
            return nospecial.test(val);
        } else {
            return false;
        }
    }

    isValidEmailList(val) {
        if (this.hasValue(val)) {
            return emailListRegex.test(val);
        } else {
            return false;
        }
    }

    isValidPostalCode(postalCode: string, countryCode: string) {
        let countryPostalCode = countryCode;
        if (countryCodes.indexOf(countryCode) === -1) {
            countryPostalCode = countryPostalCode === 'GB' ? 'UK' : 'INTL';
        }

        return postcodeValidator.postcodeValidator(postalCode, countryPostalCode);
    }

    containsOnlyAlphaNumericChars(checkString: string) {
        return alphaNumericRegex.test(checkString);
    }

    isValidZitiAttribute(checkString: string) {
        return zitiAlphaNumericRegex.test(checkString);
    }

    isHex(checkString: string) {
        return hexRegex.test(checkString);
    }

    isValidWindowsDomain(checkString: string) {
        if (this.hasValue(checkString)) {
            return isDomainName_(checkString) && checkString.length < 230;
        } else {
            return false;
        }
    }

    isValidMacAddress(checkString: string) {
        return macAddressRegex.test(checkString);
    }

    isValidIpRange(lower, upper) {
        try {
            const lowerInt = this._IpToInteger(lower);
            const upperInt = this._IpToInteger(upper);
            return lowerInt <= upperInt;
        } catch (e) {
            return false;
        }
    }

    _IpToInteger(ip) {
        if (ip.indexOf(':') >= 0) {
            return ip
                .split(':')
                .map((str) => Number('0x' + str))
                .reduce(function (int, value) {
                    return BigInt(int) * BigInt(65536) + BigInt(+value);
                });
        } else {
            // eslint-disable-next-line no-bitwise
            return (
                // eslint-disable-next-line no-bitwise
                ip.split('.').reduce(function (ipInt, octet) {
                    // eslint-disable-next-line no-bitwise
                    return (ipInt << 8) + parseInt(octet, 10);
                }, 0) >>> 0
            );
        }
    }
}
