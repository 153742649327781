import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import semver from 'semver';
import {ApiService, IdentityPreferencesService} from "@netfoundry-ui/shared/services";

import { isEmpty } from 'lodash';
import {V2NetworkCreationFormComponent} from "@netfoundry-ui/feature/form/v2-network-creation-form";

const DEFAULT_NETWORK_ID_KEY = 'default_network_id';

@Component({
    selector: 'app-select-network-modal',
    templateUrl: './select-network-modal.component.html',
    styleUrls: ['./select-network-modal.component.scss'],
})
export class SelectNetworkModalComponent implements OnInit {

    networks: any[] = [];
    selectedNetwork: any = {
      name: 'Select a Network',
      isPlaceholder: true
    };
    rememberSelection = false;

    constructor(
        private dialogRef: MatDialogRef<SelectNetworkModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public growlerService: GrowlerService,
        public networkService: NetworkServiceV2,
        public apiService: ApiService,
        private identityPreferencesService: IdentityPreferencesService,
    ) {
      this.networks = data.networks || [];
    }

    ngOnInit() {
      this.networkService._networks.subscribe((networks) => {
        if (isEmpty(networks)) {
          return;
        }
        this.networks = networks;
      });
    }

    confirm() {
      if (!this.selectedNetwork?.selected) {
        return;
      }
      if (this.rememberSelection) {
        this.identityPreferencesService.setPreference(DEFAULT_NETWORK_ID_KEY, this.selectedNetwork.id);
      }
      this.dialogRef.close(this.selectedNetwork);
    }

    cancel() {
        this.dialogRef.close();
    }

    setNetwork(network: any) {
        this.selectedNetwork = network;
        //this.dialogRef.close(network);
    }

    addNetwork(event: any) {
        this.dialogRef.close({newNetwork: true});
    }

    manageItemsSelected(event: any) {
        this.dialogRef.close();
    }

    toggleRemember(event:any) {
      if (!this.selectedNetwork?.selected) {
        return;
      }
      this.rememberSelection = !this.rememberSelection;
    }
}
